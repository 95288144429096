<template>
    <v-container class="main-wrapper" fluid fill-height>
        <transition name="fade-transition">
            <template v-if="Activity && !fetching">
                <activity-1a-examiner @completed="saveActivityCompleteStatus" v-if="Activity.type == 'examiner'"></activity-1a-examiner>
                <activity-2-join-milkyway @completed="saveActivityCompleteStatus" v-if="Activity.type == 'puzzle'"></activity-2-join-milkyway>
                <activity-3-assessment @completed="saveActivityCompleteStatus" v-if="Activity.type == 'assesment'"></activity-3-assessment>
                <ActivityKSL @completed="saveActivityCompleteStatus" v-if="Activity.type == 'key-science-lingo-left' || Activity.type == 'key-science-lingo-right'"></ActivityKSL>
                <Activity10Exploring @completed="saveActivityCompleteStatus" v-if="Activity.type == 'exploring'"></Activity10Exploring>
                <Activity8ExploringStars @completed="saveActivityCompleteStatus" v-if="Activity.type == 'exploring-stars'"></Activity8ExploringStars>
                <solar-system-activity-5 @completed="saveActivityCompleteStatus" v-if="Activity.type == 'solarSystemActivity5'"></solar-system-activity-5>
                <div class="custom-text large-text" v-if="Activity.type == 'no-activity'">No Activity Found</div>
            </template>
        </transition>
        
        <!-- Loader -->
        <transition name="fade-transition">
            <v-progress-circular
                indeterminate
                color="#000000"
                v-if="fetching"
                class="progressLoader">
            </v-progress-circular>
        </transition>
    </v-container>
</template>
<script>
import Activity1aExaminer from '@/components/Activity1AExaminer.vue';
import Activity2JoinMilkyway from '@/components/Activity2JoinMilkyway.vue';
import Activity3Assessment from '@/components/Activity3Assessment.vue';
import ActivityKSL from '../components/ActivityKSL.vue';
import Activity10Exploring from '../components/Activity10Exploring.vue';
import Activity8ExploringStars from '../components/Activity8ExploringStars.vue';
import SolarSystemActivity5 from '../components/solarSystemActivity5.vue';
export default {
    components: {Activity1aExaminer, Activity2JoinMilkyway, Activity3Assessment, ActivityKSL,
    Activity10Exploring, Activity8ExploringStars, SolarSystemActivity5},
    name: 'Activity',
    watch: { 
        $route(to, from) {  
            if(to !== from){ 
                this.$store.state.fetching = true;
                this.$store.dispatch('getActivity', this.$route.params.id);
            }
        } 
    },
    mounted(){
        this.$store.state.fetching = true;
        this.$store.dispatch('getActivities', this.$route.params.lessonID);
        this.$store.dispatch('getActivity', this.$route.params.id);
    },
    computed:{
        fetching(){
            return this.$store.state.fetching;
        },
        Activity(){
            return this.$store.state.Activity
        },
    },
    methods:{
        saveActivityCompleteStatus(){
            var _this = this
            this.$store.state.RoomDB.Lessons.forEach(element => {
                if(element.Activities.find(e=> e.id == _this.$route.params.id)){
                    element.Activities.find(e=> e.id == _this.$route.params.id ).visited = 'visited';
                    console.log(element.Activities.find(e=> e.id == _this.$route.params.id ).visited)
                }
            }); 
            localStorage.setItem("RoomDB", JSON.stringify(_this.$store.state.RoomDB));
        }
    }
}
</script>
<style scoped>
.container.container--fluid.fill-height{
    padding: 0px;
    background-size: 100% 100%;
}

</style>