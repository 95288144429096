<template>
    <v-container
        fluid
        fill-height
        v-bind:style="{
            'background-image':
                'url(' + require(`@/assets/img/` + currentBackground) + ')',
            'background-size': 'cover',
        }"
        :class="[Activity.class]"
    >
        <!-- Count Up Timer -->
        <transition name="fade-transition">
            <template>
                <CountUpTimer
                    ref="assessmentTimeUpTimer"
                    class="timer"
                ></CountUpTimer>
            </template>
        </transition>

        <!-- Tools -->
        <transition name="fade-transition" v-if="!showPortal">
            <template>
                <Tools
                    ref="activityTools"
                    @toolClick="selectTool"
                    :tools="Activity.tools"
                    :class="toolbarStatus"
                ></Tools>
            </template>
        </transition>

        <!-- template for dr astro narrations -->
        <transition name="fade-transition" v-if="showDrAstroNarration">
            <div class="dr-astro-narration">
                <transition name="fade-transition">
                    <div class="dr-astro">
                        <transition name="slide-fade">
                            <v-img v-if="showDrAstro" alt="Dr. Astro Image" src="@/assets/img/activities/activity_1a/dr-astro.png" width="350" height="400" eager contain></v-img>
                        </transition>
                        <transition name="scale-transition">
                            <div class="instruction-wrapper" > 
                                <p id="dr-astro-narration-text-box"></p>
                            </div>
                        </transition>
                    </div>
                </transition>
            </div>
        </transition>

        <!-- template for 2b narrations -->
        <transition name="fade-transition" v-if="show2BNarration">
            <div :class="[hintNarration ? 'dr-astro-narration' : '']">
                <transition name="fade-transition">
                    <v-row>
                        <v-col
                            cols="12"
                            sm="2"
                            v-if="!showActivityTutorial && !showActivityIntro && !showPortal "
                        >
                            <div
                                id="twoB-milkyway-narration"
                                class="hint-narration"
                                v-if="hintNarration"
                            >
                                {{ hintNarration }}
                            </div>
                        </v-col>
                    </v-row>
                </transition>
            </div>
        </transition>

        <!-- Intro State -->
        <transition name="fade-transition">
            <v-row  style="align-self:start"  v-if="currentState == 'intro'">

                <!-- Jetpack -->
                <v-col cols="12" sm="2">
                    <img 
                        v-if="jetPackImage && !showPortal"
                        :class="jetPackBounce"
                        class="jet-pack"
                        :src="
                            require(`@/assets/img/activities/activity_1a/${jetPackImage}`)
                        "
                    />
                </v-col>
                <!-- current tool images -->
                 <v-col cols="12" sm="8" style="position:relative">
                    <img :src="require(`@/assets/img/activities/activity_1a/${Activity.states.intro.second_background}`)" style="opacity:0; position:absolute; max-width:100%; max-height:100%;" id="milky-way-container">
                        <div v-if="!showPortal && currentState == 'intro'" class="examiner-wrapper" :style="{'width': WrapperWidth+'px'}">    
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 765 640" class="second-background"  :class="currentToolClass">
                            <image width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.intro.second_background}`)"></image>
                        </svg>
                    </div>
                </v-col> 
                <v-col cols="12" sm="2">
                    <!-- Loader -->
                    <transition name="fade-transition">
                        <div class="full-screen-loader" v-if="videoLoading">
                            <v-progress-circular
                                indeterminate
                                color="#000000"
                                class="progressLoader"
                            >
                            </v-progress-circular>
                        </div>
                    </transition>

                    <!-- Portal -->
                    <transition name="fade-transition">
                        <!-- <v-img
                            class="twoB-jumps"
                            @load="gifEnded"
                            v-if="showPortal"
                            :src="ImageURL()"
                        ></v-img> -->
                        <div
                            class="intro-video-wrapper"
                            v-if="showPortal"
                        >
                            <video
                                class="twoB-jumps"
                                muted
                                autoplay
                                id="myVideo"
                                @ended="gifEnded"
                            >
                                <source :src="ImageURL()" type="video/mp4" />
                            </video>
                        </div>
                    </transition>

                    <!-- Intro -->
                    <transition name="fade-transition">
                        <div
                            class="intro-video-wrapper"
                            v-if="showActivityIntro"
                        >
                            <video
                                muted
                                autoplay
                                id="myVideo"
                                @ended="introVideoEnded"
                                @loadeddata="checkLoading"
                            >
                                <source :src="videoURL()" type="video/mp4" />
                            </video>
                        </div>
                    </transition>

                    <!-- Tutorial -->
                    <transition name="fade-transition">
                        <div
                            class="intro-video-wrapper"
                            v-if="showActivityTutorial"
                        >
                            <video
                                autoplay
                                id="myVideo"
                                @ended="tutorialVideoEnded"
                                @loadeddata="checkLoading"
                            >
                                <source
                                    src="@/assets/videos/activities/activity_1a/MW-Tutorial.mp4"
                                    type="video/mp4"
                                />
                            </video>
                            <div
                                class="skip custom-font"
                                v-if="showActivityTutorial"
                                @click="skipVideo"
                            >
                                Skip
                            </div>
                        </div>
                    </transition>
                </v-col>
            </v-row>
        </transition>

        <!-- milkyway state -->
       <transition name="fade-transition">
            <v-row style="align-self:start" v-if="currentState == 'milkyway'">
                <!-- Jet pack image -->
                <v-col cols="12" sm="2" v-if="!showActivityTutorial && !showActivityIntro && !showPortal">
                    <img v-if="jetPackImage" :class="jetPackBounce" class="jet-pack" :src="require(`@/assets/img/activities/activity_1a/${jetPackImage}`)">
                </v-col>

                <!-- selected tool images based on state and tool  -->
                <v-col cols="12" sm="8" align-self="start" style="position:relative" v-if="!showActivityTutorial && !showActivityIntro">
                    <img :src="require(`@/assets/img/activities/activity_1a/${Activity.states.milkyway.second_background}`)" style="opacity:0; position:absolute; max-width:100%; max-height:100%;" id="milky-way-container">
                   
                    <div class="examiner-wrapper" :style="{'width': WrapperWidth+'px'}" v-if="!showPortal">    
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 765 640" class="second-background"  :class="currentToolClass">
                            <image  v-if="!starActive  && !nebulaActive && !galaxyCenter" width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.milkyway.second_background}`)"></image>
                            <!-- for star bulb -->
                            <image v-if="starActive" width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.milkyway.milkyway_star_background}`)"></image>
                            <!-- for nebula bulb -->
                            <image v-if="nebulaActive" width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.milkyway.milkyway_nebula_background}`)"></image>
                            <!-- for center galaxy -->
                            <image v-if="galaxyCenter" width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.milkyway.milkyway_center_background}`)"></image>
                            <!-- Galaxy Center -->
                            <a v-if="galaxyCenter" @click="starClick('galaxyCenter')" class="pathBulb">
                                <path d="M347,273,350,263,348,253,341,247,333,245,327,247,323,253,322,259,322,266,324,272,326,279,342,280,331,282z" />
                                <rect x="301" y="234" fill="#fff" opacity="0" width="139" height="156"></rect>
                            </a>
                            <!-- Nebula -->
                            <a v-if="nebulaActive" @click="starClick('nebula')" class="pathBulb">
                                <path d="M124,466,126,457,128,450,130,441,126,432,120,428,113,428,107,431,102,436,100,442,102,449,104,454,107,460,111,465,116,465z" />
                                <rect x="2" y="412" fill="#fff" opacity="0" width="130" height="90"></rect>
                            </a>
                            <!-- Star -->
                            <a v-if="starActive" class="star pathBulb" @click="starClick('star')" >
                                <path d= "M374,541,377,536,380,528,380,518,377,511,370,506,362,506,357,510,353,516,352,522,352,529,355,535,358,539,362,542z" />
                                <rect x="296" y="499" fill="#fff" opacity="0" width="81" height="88"></rect>
                            </a>
                        </svg>
                        <!-- Measuring Tape -->
                        <transition name="fade-transition">
                            <div v-if="currentToolImage && currentToolClass == 'measuring-tape-opened'" id="currentToolImage" class="currentToolImage" :class="currentToolClass">
                                <div :class="measuringTapeClass">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 775 150">
                                        <image width="775" height="150" :xlink:href="require(`@/assets/img/activities/activity_1a/${currentToolImage}`)"></image> 
                                        
                                        <a @click="openMeasuringTape">
                                            <rect x="1" y="0" fill="#fff" opacity="0" width="181" height="150"></rect>
                                        </a>
                                    </svg>
                                </div>
                            </div>
                        </transition>
                    </div>

                    <!-- Analyzer -->
                    <img v-if="currentToolClass == 'analyzer'">
                    <transition name="fade-transition" v-if="currentToolClass == 'analyzer'">
                        <div v-if="currentToolImage" id="currentToolImage" class="currentToolImage" :class="currentToolClass">
                           <img :src="require(`@/assets/img/activities/activity_1a/${currentToolImage}`)">
                        </div>
                    </transition>

                </v-col>
                <!-- Continue Btn -->
                <v-col cols="12" sm="2">
                    <transition name="fade-transition" v-if="continueBtn">
                        <v-btn v-if="continueBtn" class="skip custom-font" @click="continueBtnClick(0)" >Continue</v-btn>
                    </transition>
                </v-col>
                 <!--Back Button  -->
                <v-col cols="12" sm="2">
                    <v-icon ref="backIcon"  class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
                        west
                    </v-icon>
                </v-col>
                
            </v-row>
        </transition>   
        <!-- Notebook Generic -->
        <transition>
            <div v-if="notebookToolImage && notebookToolClass == 'digital-notebook' && this.noteBookStatus" id="notebookToolImage" class="notebookToolImage" :class="notebookToolClass">
            <!-- <div v-if="this.noteBookStatus" id="digital-notebook.png" class="digital-notebook"> -->
                <img :src="require(`@/assets/img/activities/activity_1a/${notebookToolImage}`)">
                <div :class="notebookToolClass" class="digital-notebook-textarea">
                    <textarea v-model="noteBookContent"></textarea>
                </div>
            </div>
        </transition>

        <!-- Nebula -->
        <transition name="fade-transition">
            <v-row style="align-self:start" v-if="currentState == 'nebula'">
                <v-col cols="12" sm="2" v-if="!showActivityTutorial && !showActivityIntro && !showPortal">
                    <img v-if="jetPackImage" :class="jetPackBounce" class="jet-pack" :src="require(`@/assets/img/activities/activity_1a/${jetPackImage}`)">
                </v-col>

                <!-- Render images and clickbles based on selected state and tool  -->
                <v-col cols="12" sm="8" align-self="start" style="position:relative" v-if="!showActivityTutorial && !showActivityIntro">
                    <img :src="require(`@/assets/img/activities/activity_1a/${Activity.states.nebula.second_background}`)" style="opacity:0; position:absolute; max-width:100%; max-height:100%;" id="milky-way-container">
                   
                    <div class="examiner-wrapper" :style="{'width': WrapperWidth+'px'}" v-if="!showPortal">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1045 715" class="second-background"  :class="currentToolClass">
                            <image width="1045" height="715" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.nebula.second_background}`)"></image> 
                            
                            <a @click="zoomNebula(1)" v-if="!nebulaExplore1 && !nebulaExplore2" :class="[!nebulaExplore1 && !nebulaExplore2 ? 'pathBulb' : '']">
                                <path d="M222,265,225,255,228,248,231,237,225,221,216,221,204,221,197,227,194,239,196,251,200,258,210,264z" />
                                <rect x="187" y="214" fill="#fff" opacity="0" width="50" height="68"></rect>
                            </a>
                            
                            <a @click="zoomNebula(2)" v-if="nebulaExplore1 && !nebulaExplore2" :class="[nebulaExplore1 && !nebulaExplore2 ? 'pathBulb' : '']">
                                <!-- <path d="M695,480,701,471,706,463,709,453,708,441,698,433,687,430,677,430,670,437,668,447,668,456,671,463,674,471,682,477z" /> -->
                                <path :style="[nebulaExplore1 ? {fill: 'fff'} : {}]" d="M696,476,702,470,706,461,709,449,705,436,693,432,683,435,673,440,673,451,674,458,679,468,683,477z" />
                                <rect x="666" y="430" fill="#fff" opacity="0" width="50" height="68"></rect>
                            </a>
                        </svg>
                    </div>
                </v-col>

                <!-- Inside Telecsope -->
                <v-col cols="12" sm="2">
                    <img v-if="currentToolClass == 'telescope'" :src="require(`@/assets/img/activities/activity_1a/${currentToolImage}`)" class="telescope-zoomed">
                    <template v-if="(currentToolClass == 'telescope') && (nebulaZoom1 || nebulaZoom2)">
                        <!-- <span class="exit-full-screen" @click="exitFullScreen">X</span> -->
                        <v-icon class="exit-full-screen" @click="exitFullScreen" >
                            close
                        </v-icon>

                        <img :src="require(`@/assets/img/activities/activity_1a/${currentToolImage}`)" class="telescope-zoomed">
                        <div  v-if="nebulaZoom1" class="nebula-zoom-container">
                            <img v-if="nebulaZoom1" contain class="nebula-zoom nebula-zoom-1" :src="require(`@/assets/img/activities/activity_1a/nebula-zoom-1.jpg`)">
                        </div>
                        <div v-if="nebulaZoom2" class="nebula-zoom-container">   
                            <img v-if="nebulaZoom2" contain class="nebula-zoom nebula-zoom-2" :src="require(`@/assets/img/activities/activity_1a/nebula-zoom-2.jpg`)">  
                        </div>
                    </template>
                </v-col>

                <!-- Continue Button -->
                <v-col cols="12" sm="2">
                    <transition name="fade-transition" v-if="continueBtn">
                        <v-btn v-if="continueBtn" class="skip custom-font" @click="continueBtnClick(1)" >Continue</v-btn>
                    </transition>
                </v-col> 
            </v-row>
        </transition>

        <!-- Star -->
        <transition name="fade-transition">
            <v-row style="align-self:start" v-if="currentState == 'star'">
                <!-- Jet Pack Image -->
                <v-col cols="12" sm="2">
                    <img v-if="jetPackImage" :class="jetPackBounce" class="jet-pack" :src="require(`@/assets/img/activities/activity_1a/${jetPackImage}`)">
                    <div id="twoB-star-narration" class="hint-narration" v-if="hintNarration">{{hintNarration}}</div>
                </v-col>
                <!-- Render images and clickbles based on selected state and tool  -->
                <v-col cols="12" sm="8" align-self="start" style="position:relative">

                    <img :src="require(`@/assets/img/activities/activity_1a/${Activity.states.star.second_background}`)" style="opacity:0; position:absolute; max-width:100%; max-height:100%;" id="milky-way-container">

                    <div class="examiner-wrapper" :style="{'width': WrapperWidth+'px'}">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 765 640" class="second-background"  :class="currentToolClass">
                            <image width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_1a/${Activity.states.star.second_background}`)"></image>
                        </svg>
                    </div>
                    <!-- Analyzer -->
                    <img v-if="currentToolClass == 'analyzer'">
                    <transition name="fade-transition" v-if="currentToolClass == 'analyzer' && !scientificNarrationDisplayed">
                        <div v-if="currentToolImage" id="currentToolImage" class="currentToolImage star-tool" :class="currentToolClass">
                           <img :src="require(`@/assets/img/activities/activity_1a/analyzer.png`)">
                        </div>
                    </transition>
                </v-col>
            </v-row>
        </transition>

        <!-- Next button -->
        <v-row>
            <transition name="fade-transition">
                <v-btn v-if="nextActivityBtn" class="skip custom-font" @click="goToNextActivity" >Next Activity</v-btn>
            </transition>
        </v-row>
    </v-container>
</template>
<script>

import CountUpTimer from '../components/CountUpTimer';
import Tools from '../components/Tools';

export default {
    name: 'Activity1AExaminer',
    components: {
        CountUpTimer,
        Tools
    },
    data(){
        return{
            Activity:{
                id: '012',
                tools:[
                    {name: 'Measuring Tape', icon_url: 'measuring-tape-icon-for-toolkit.png', status: 'disabled', tool_main_img: 'measuring-tape-opened.png', class:'measuring-tape-opened', tool_used: false},
                    {name: 'Clock', icon_url: 'clock-icon-for-toolkit.png', status: 'disabled', tool_main_img: '', class:'', tool_used: false},
                    {name: 'Telescope', icon_url: 'telescope-icon-in-toolkit.png', status: 'disabled', tool_main_img: '2b-telescope.png', class:'telescope', tool_used: false},
                    {name: 'JetPack', icon_url: 'jetpack-icon-for-toolkit.png', status: 'active', tool_main_img: '2b-Jetpack.png', class:'jetpack', tool_used: false},
                    {name: 'Analyzer', icon_url: 'analyzer-icon-for-toolkit.png', status: 'disabled', tool_main_img: 'milkyway-analyzer.png', class:'analyzer', tool_used: false},
                    {name: 'Notebook', icon_url: 'notebook-icon-for-toolkit.png', status: 'disabled', tool_main_img: 'digital-notebook.png', class:'digital-notebook', tool_used: false},
                ],
                states: {
                    intro:{
                        name: 'Intro',
                        background: 'milky-way.png',
                        second_background: 'milky-way.png',
                        Narration: [
                            {id:'1', content: ''}
                        ]
                    },
                    milkyway:{
                        name: 'Milky-way',
                        background: 'purple-galaxy-background.png',
                        second_background: 'milky-way-attached.png',
                        third_background: 'milky-way-attached-bulb.png',
                        milkyway_star_background: 'milky-way-attached-star.png',
                        milkyway_nebula_background: 'milky-way-attached-nebula.png',
                        milkyway_center_background: 'milky-way-attached-galaxy.png',
                        Narration: [
                            {id:'1', content: 'Where am I?^I know where we are! We are in the Milky Way Galaxy!!'},
                            {id:'2', content: 'That is right 2B. Do you know what the Milky Way’s distance across/ diameter is?'},                    
                            {id:'3', content: 'Let’s investigate the other things that are in the galaxy!^Remember that you can record your observations or data in your digital notebook!^What about observing the center of the Milky Way? Ever wondered what is in the center of this swirling galaxy?^Click on the lightbulb to observe the center of the Milky Way.'}, 
                            {id:'4', content: 'Select analyzer tool to observe a magnified image of the center of the Milky Way.'},
                            // {id:'5', content: 'Did you know that our solar system lies on one of the spiral arms of the Milky Way Galaxy. The Milky Way we see is the combined light from millions of stars.'} ,
                            {id:'5', content: 'Did you know that our solar system lies on one of the spiral arms of the Milky Way Galaxy. We can actually see the stars that are beyond our solar system, out on the Orion-Cygnus Arm of the galaxy. The Milky Way we see is the combined light from millions of stars. When you see dark patches, you are seeing clouds of dust that block out the light from the stars. We can’t see the center of our galaxy because there are too many clouds of dust and gas in the way.'} ,
                            {id:'6', content: "Alright scientists, let's explore! Click on the purple cloud on the left. Do you know what it is?"} ,
                            {id:'7', content: "Hey students! Remember you can use the measuring tape to measure the distance or size of things."} ,
                            {id:'8', content: "Scientist Highlight:# Astronomer Charles Messier# The first galaxies were identified in the 17th Century by the French astronomer Charles Messier, although at the time he did not know what they were."} ,
                            {id:'9', content: "The Milky Way is 105,700 light-years across diameter, an invisible line that extends from the edge of our galaxy straight through the galaxy center to the opposite edge on the other side."} ,
                            
                        ]
                    },
                    nebula:{
                        name: 'Nebula',
                        background: 'purple-galaxy-background.png',
                        second_background: 'nebula-large-bulbs.png',
                        Narration: [
                            {id:'1', content: 'WOW! This is a NEBULA.^Click on the lightbulbs to make observations.'},
                            {id:'2', content: 'Nebulae are necessary. As you observed, gas and dust make up a nebula and stars are born when large gas clouds collapse under gravity.'},
                            {id:'3', content: 'Remember students you can use the telescope to observe an object closer.'},
                        ]
                    },
                    star:{
                        name: 'Star',
                        background: 'purple-galaxy-background.png',
                        second_background: 'Star-main.png',
                        Narration: [
                            {id:'1', content: "Ok! Now that you know what a nebula is, let's move to the next icon."},
                            {id:'2', content: 'Click on the glowing lightbulb.'},
                            {id:'3', content: 'Inside a galaxy we can also find an immeasurable amount of stars.^Use the analyzer tool to make observations about the stars.'},
                            {id: '4', content: 'Scientist Highlight:# Astronomer Charles Messier# The first galaxies were identified in the 17th Century by the French astronomer Charles Messier, although at the time he did not know what they were.'},
                        ]
                    }
                },
            },
            currentBackground: 'purple-galaxy-background.png',
            // starting state of activity 1A
            currentState: 'intro',
            jetPackImage: '2b-Happy-talk-200x200.png',
            jetPackBounce: '',
            showPortal: false,
            currentTool: "",
            currentToolImage: "",
            currentToolClass: "",
            /** for notebook */
            notebookToolImage: "digital-notebook.png",
            notebookToolClass: "digital-notebook",
            secondaryCurrentTool: "Notebook",
            noteBookContent: '',
            noteBookStatus: false,

            WrapperWidth: '',
            showActivityTutorial: false,
            showActivityIntro: false,
            videoLoading: true,
            showDrAstroNarration: false,
            showDrAstro: false,
            show2BNarration: false,
            hintNarration: '',
            toolbarStatus: '',
            toolHintsNarrationTimeout1: null,
            drAstroTimeOut: null,
            drAstroTimeOut2: null,
            toolHintsNarrationTimeout2: null,
            toolHintsNarrationTimeout3: null,
            toolHintsNarrationTimeout4: null,
            toolHintsNarrationTimeout5: null,
            measuringTapeClass: '',
            galaxyCenter: false,
            nebulaActive: false,
            starActive: false,
            continueBtn: false,
            nebulaZoom1: false,
            nebulaZoom2: false,
            nebulaExplore1: false,
            nebulaExplore2: false,
            scientificNarrationDisplayed: false,
            nextActivityBtn: false,
            go: true,
        }
    },
    created(){
        this.showPortal = true;
        window.addEventListener("resize", this.screenResize);
        console.log("X:" + this.Activity.states.intro.background);
    },
    destroyed(){
        console.log("Destroyed");
        clearTimeout(this.drAstroTimeOut);
        clearTimeout(this.drAstroTimeOut2);
        clearTimeout(this.toolHintsNarrationTimeout1);
        clearTimeout(this.toolHintsNarrationTimeout2);
        clearTimeout(this.toolHintsNarrationTimeout3);
        clearTimeout(this.toolHintsNarrationTimeout4);
        clearTimeout(this.toolHintsNarrationTimeout5);
    },
    mounted() {
        document.addEventListener('visibilitychange', function() {
            if(document.hidden) {
                this.go = false;
                console.log("Tab Inactive" + this.go);
                // tab is now inactive
                // temporarily clear timer using clearInterval() / clearTimeout()
            }
            else {
                // tab is active again
                // restart timers
                this.go = true;
                console.log("Tab Active");

            }
        });
    },

    methods: {
        /** method to set the image url of the gif */
        ImageURL(){
            var timestamp = new Date().getTime()
            // return require(`@/assets/img/activities/activity_1/Jump-out.gif`) + '?' + timestamp
            return require(`@/assets/videos/activities/activity_1a/Jump-out-space.mp4`) + '?' + timestamp
        },
        /** method to call when gif displayed */
        gifEnded(){
            this.videoLoading = false;
            this.showPortal = false;
            this.showActivityIntro = true
        },
        /** method to set the url of the intro video */
        videoURL(){
            var timestamp = new Date().getTime()
            return require(`@/assets/videos/activities/activity_1a/MW-Intro.mp4`) + '?' + timestamp
        },
        /** method to call when intro video ended */
        introVideoEnded(){
            this.showActivityIntro= false;
        },
        /** method to set video loading to false */
        checkLoading(){
            this.videoLoading = false;
        },
        /** method to call when tutorial video ended */
        tutorialVideoEnded(){
            // call skipVideo function
            this.skipVideo();
        },

        /** method to skip tutorial video */
        skipVideo(){
            this.showActivityTutorial = false;
            this.show2BNarration = true;
            this.toolbarStatus = 'toolbar-disabled';
            this.showDrAstro = true;

            this.toolHintsNarration(0, this.Activity.states.milkyway.Narration[0].content, 80);
            /** call dr. astro when 2b narration completed,  */
            this.toolHintsNarrationTimeout2 = setTimeout(()=>{
                this.show2BNarration = false;
                clearTimeout(this.toolHintsNarrationTimeout1);
                this.showDrAstroNarration = true;
                this.drAstroNarrationAnimation(0, this.Activity.states.milkyway.Narration[1].content, 80, this.showDrAstroNarration, "dr-astro-narration-text-box");
               
                /** when dr astro narration gets completed */
                this.toolHintsNarrationTimeout3 = setTimeout(()=>{
                    this.show2BNarration = true;
                    this.toolbarStatus = 'toolbar-disabled';
                    this.toolHintsNarration(0, this.Activity.states.milkyway.Narration[6].content, 80);
                     this.toolHintsNarrationTimeout4 = setTimeout(()=>{
                        this.showDrAstroNarration = false;
                        clearTimeout(this.drAstroTimeOut);
                        clearTimeout(this.toolHintsNarrationTimeout1);

                        // set old tool status to disable 
                        this.currentTool.status = "disabled"
                        // update tool
                        this.currentTool = this.searchTools("Measuring Tape", this.Activity.tools);
                        this.currentTool.status = "active";
                        // update state
                        this.currentState = "milkyway";
                        this.toolbarStatus = '';
                        console.log("Actual End of First Communication");
                    }, (80 * this.Activity.states.milkyway.Narration[6].content.length + 2500));
                }, ((80 * this.Activity.states.milkyway.Narration[1].content.length) + 3500));
            }, (80 * this.Activity.states.milkyway.Narration[0].content.length) + 6500);
            
        },

        /** function to get object from array */
        searchTools(nameKey, myArray){
            for (var i=0; i < myArray.length; i++) {
                if (myArray[i].name === nameKey) {
                    return myArray[i];
                }
            }
        },

        /** method to perform action on tool selection */
        selectTool(mainImg, toolClass, item){
            if(document.getElementById('milky-way-container')){
                this.WrapperWidth = document.getElementById('milky-way-container').clientWidth;
            }
            console.log("Item tool: " + item);
            this.showDrAstroNarration = false;
            this.show2BNarration = false;
            clearTimeout(this.drAstroTimeOut);
            console.log("Set out");
            clearTimeout(this.toolHintsNarrationTimeout1);
            clearTimeout(this.toolHintsNarrationTimeout2);
            clearTimeout(this.toolHintsNarrationTimeout3);
            clearTimeout(this.toolHintsNarrationTimeout4);
            if(toolClass == 'jetpack'){
                this.currentTool = this.searchTools("JetPack", this.Activity.tools);
                this.currentTool.status = "disabled";
                this.jetPackImage = mainImg;
                this.jetPackBounce = 'fly';
                setTimeout(() => {
                    this.jetPackBounce = 'bounce';
                    this.videoLoading = true;
                    this.showActivityTutorial = true;
                }, 6000);
            }
            else if(toolClass == 'measuring-tape-opened'){
                console.log("Measuring Tape Opened");
                this.currentToolImage = mainImg;
                this.currentToolClass = toolClass;
                this.currentTool.status = "disabled";
                console.log("CI: " + this.currentToolImage);
                console.log("CTL: " + this.currentToolClass);
            }else if(toolClass == 'analyzer'){
                this.noteBookStatus = false;
                if(this.currentState == 'milkyway'){
                    console.log("Analyzer Clicked"+this.currentState);
                    this.currentToolImage = mainImg;
                    this.currentToolClass = toolClass;
                    this.searchTools("Notebook", this.Activity.tools).status = "disabled";
                    this.searchTools("Analyzer", this.Activity.tools).status = "disabled";
                    this.drAstroTimeOut = setTimeout(() => {
                        this.searchTools("Notebook", this.Activity.tools).status = "active";
                        this.showDrAstroNarration = true;
                        this.drAstroNarrationAnimation(0, this.Activity.states.milkyway.Narration[4].content, 80, this.showDrAstroNarration, "dr-astro-narration-text-box");
                        this.toolHintsNarrationTimeout5 = setTimeout(()=>{
                            this.showDrAstroNarration = false;
                            clearTimeout(this.drAstroTimeOut);
                            this.toolbarStatus = '';
                            this.continueBtn = true;
                            this.currentTool.status = "disabled"
                            clearTimeout(this.drAstroTimeOut);
                            clearTimeout(this.toolHintsNarrationTimeout5);
                        }, ((80 * this.Activity.states.milkyway.Narration[4].content.length) + 3500) );//2500
                    }, 2000);
                }else if(this.currentState == 'star'){
                    this.currentToolImage = mainImg;
                    this.currentToolClass = toolClass;
                    this.searchTools("Notebook", this.Activity.tools).status = "disabled";
                    this.searchTools("Analyzer", this.Activity.tools).status = "disabled";
                    setTimeout(() => {
                        this.scientificNarrationDisplayed = true; 
                        this.drAstroTimeOut = setTimeout(() => {
                            this.showDrAstroNarration = true;
                            this.drAstroNarrationAnimation(0, this.Activity.states.star.Narration[3].content, 80, this.showDrAstroNarration, "dr-astro-narration-text-box");
                            setTimeout(() => {
                                this.showDrAstroNarration = false;
                                this.$emit('completed');
                                this.nextActivityBtn = true;
                                this.searchTools("Notebook", this.Activity.tools).status = "active";
                                this.toolbarStatus = "disabled";
                            }, (80 * this.Activity.states.star.Narration[3].content.length) + 3000);
                        }, 1000);
                    }, 4000);
                    // this.show2BNarration = true;
                    // this.toolbarStatus = 'toolbar-disabled';
                    // this.toolHintsNarrationTimeout1 = setTimeout(()=>{
                    //     this.toolHintsNarration(0, this.Activity.states.star.Narration[2].content, 80);
                    // }, 500);
                    // setTimeout(()=>{
                    //     clearTimeout(this.toolHintsNarrationTimeout1);
                    // }, ((80 * this.Activity.states.star.Narration[2].content.length) + 5000));
                }
            }else if(toolClass == 'digital-notebook'){
                this.noteBookStatus = !this.noteBookStatus;
                console.log("notebook Clicked" + this.noteBookStatus);
                // this.currentToolImage = mainImg;
                // this.currentToolClass = toolClass;
                this.notebookToolImage = mainImg;
                this.notebookToolClass = toolClass;
                this.secondaryCurrentTool = "Notebook";
            }else if(toolClass == 'telescope'){
                console.log("Telescope Clicked");
                this.currentToolImage = mainImg;
                this.currentToolClass = toolClass;
                this.searchTools("Telescope", this.Activity.tools).status = "disabled";
            }
        },
        /** method to open measuring tape */
        openMeasuringTape(){
            this.currentTool.status = "disabled";
            this.hintNarration = "";
            if(this.measuringTapeClass == ''){
                console.log("Opened");
                this.measuringTapeClass = 'opened';
                this.show2BNarration = true;
                this.toolbarStatus = 'toolbar-disabled';

                // show 2b narration about galaxy diameter
                setTimeout(() => {
                    this.show2BNarration = true;
                    this.toolHintsNarration(0, this.Activity.states.milkyway.Narration[8].content, 80);//3 (4) narrations
                    console.log("New Started...!")
                    setTimeout(()=>{
                        console.log("Old started!");
                        this.show2BNarration = true;
                        this.toolHintsNarration(0, this.Activity.states.milkyway.Narration[2].content, 80);//3 (4) narrations
                        this.toolHintsNarrationTimeout4 = setTimeout(()=>{
                            this.showDrAstroNarration = false;
                            this.show2BNarration = false;
                            this.currentToolImage = "";
                            this.currentToolClass = "";
                            this.searchTools("Notebook", this.Activity.tools).status = "active";
                            // update to next state
                            this.galaxyCenter = true;
                            this.toolbarStatus = '';
                            clearTimeout(this.toolHintsNarrationTimeout4);
                            console.log("Measuring Tape Communication Done!");
                        }, 80 * this.Activity.states.milkyway.Narration[2].content.length + 11500);//9000 
                    },this.Activity.states.milkyway.Narration[8].content.length * 80 + 3500);
                }, 1000);

                
            }
            else{
                console.log("Closed");
                this.measuringTapeClass = ''; 
                this.currentToolImage = "";
                this.currentToolClass = "";
            }
        },
        /** method to call when click on bulbs inside nebula */
        zoomNebula(optionSelected){
            if(this.currentToolClass == 'telescope'){
                this.searchTools("Notebook", this.Activity.tools).status = "disabled";
                if(optionSelected == 1){
                    this.nebulaZoom1 = true;
                    this.nebulaZoom2 = false;
                    this.nebulaExplore1 = true;
                }
                else if(optionSelected == 2){
                    this.nebulaZoom1 = false;
                    this.nebulaZoom2 = true;
                    this.nebulaExplore2 = true;
                }
                else{
                    this.nebulaZoom1 = false;
                    this.nebulaZoom2 = false;
                    this.nebulaExplore1 = false;
                    this.nebulaExplore2 = false;
                }
            }
            else{
                this.$store.state.responseMsg = 'Kindly select telescope tool first!';
                this.$store.state.ResponseClass = '';
                this.searchTools("Telescope", this.Activity.tools).status = "active";
            }
        },
        /** method to exit from nebula (stars inside nebula) screen */
        exitFullScreen(){
            this.nebulaZoom1 = false;
            this.nebulaZoom2 = false;
            this.searchTools("Notebook", this.Activity.tools).status = "active";
            if(this.nebulaExplore1 && this.nebulaExplore2){
                if(this.nebulaExplore1 && this.nebulaExplore2){
                    console.log("Both Explored. Add Continue btn here" + this.currentToolClass);
                    this.continueBtn = true;
                    this.searchTools("Telescope", this.Activity.tools).status='disabled';
                }
            }
        },

        /** method to show dr. astro narration */
        drAstroNarrationAnimation(scintificNarrationLetterIndex, narrationText, narrationSpeed, renderCondition, appendTo){
            if(scintificNarrationLetterIndex == 0){
                this.hintNarration = "";
                this.noteBookStatus = false;
            }
            // if(!this.go){
            //     narrationSpeed = 1000;
            // }else{
            //     narrationSpeed = 80;
            // }
            // console.log("NSpeed " + narrationSpeed);
            this.drAstroTimeOut = setTimeout(()=>{
                if (renderCondition && scintificNarrationLetterIndex < narrationText.length) {
                    var charToAppend = narrationText.charAt(scintificNarrationLetterIndex);
                    if(charToAppend === '#'){
                        charToAppend = "<br>"
                    }
                    this.drAstroNarration += charToAppend;
                    document.getElementById(appendTo).innerHTML += charToAppend;
                    scintificNarrationLetterIndex++;
                    setTimeout(this.drAstroNarrationAnimation(scintificNarrationLetterIndex, narrationText, narrationSpeed, renderCondition, appendTo));
                }
                else{
                    setTimeout(()=>{
                        this.showDrAstroNarration = false;
                        this.show2BNarration = false;
                    },2000);
                }
            },narrationSpeed);
        },

        /** method to show 2B narration */
        toolHintsNarration(narrationLetterIndex, narrationText, narrationSpeed){
                if(narrationLetterIndex == 0){
                    this.hintNarration = "";
                    this.noteBookStatus = false;
                }
                this.toolHintsNarrationTimeout1 = setTimeout(()=>{
                if (narrationLetterIndex < narrationText.length) {
                    if (narrationText.charAt(narrationLetterIndex) != '^') {
                        this.hintNarration += narrationText.charAt(narrationLetterIndex);
                        narrationLetterIndex++;
                        setTimeout(this.toolHintsNarration(narrationLetterIndex, narrationText, narrationSpeed), narrationSpeed);
                    }else{
                        setTimeout(()=>{
                            this.hintNarration = "";
                            setTimeout(() => {
                                narrationLetterIndex++;
                                setTimeout(this.toolHintsNarration(narrationLetterIndex, narrationText, narrationSpeed), narrationSpeed)
                            },1000);
                        },2000);
                    }
                }
                else{
                    setTimeout(() => {
                        this.hintNarration = '';
                        this.show2BNarration = false;
                        this.showDrAstroNarration = false;
                        this.toolbarStatus = '';
                    }, 2000);
                }
            },narrationSpeed);
            
        },

        /** method when any of the star clicked */
        starClick(star){
            if(star == "galaxyCenter"){
                this.showDrAstroNarration = false;
                this.show2BNarration = false;
                clearTimeout(this.drAstroTimeOut);
                clearTimeout(this.toolHintsNarrationTimeout1);
                clearTimeout(this.toolHintsNarrationTimeout2);
                clearTimeout(this.toolHintsNarrationTimeout3);
                this.currentToolImage = "";
                this.currentToolClass = "";
                 
                this.show2BNarration = true;
                this.toolbarStatus = 'toolbar-disabled';
                this.toolHintsNarration(0, this.Activity.states.milkyway.Narration[3].content, 80);
                setTimeout(() => {
                    this.show2BNarration = false;
                    clearTimeout(this.drAstroTimeOut);
                    clearTimeout(this.toolHintsNarrationTimeout1);
                    this.currentToolImage = "";
                    this.currentToolClass = "";
                    // set old tool status to disable 
                    this.currentTool.status = "disabled"
                    // update tool
                    console.log("Tool: Analyzer" )
                    this.currentTool = this.searchTools("Analyzer", this.Activity.tools);
                    this.currentTool.status = "active";
                    this.toolbarStatus = '';

                    // set galaxyCenter to false
                    this.galaxyCenter = false;
                }, 80 * this.Activity.states.milkyway.Narration[3].content.length + 2500);

            }
            else if(star == "nebula"){
                this.currentState = 'nebula';
                this.searchTools("Notebook", this.Activity.tools).status = "disabled";
                this.toolHintsNarrationTimeout1 = setTimeout(()=>{
                    this.show2BNarration = true;
                    this.toolbarStatus = 'toolbar-disabled';
                    this.toolHintsNarration(0, this.Activity.states.nebula.Narration[0].content, 80);
                    /** call dr. astro when 2b narration completed,  */
                    this.toolHintsNarrationTimeout2 = setTimeout(()=>{
                        this.show2BNarration = false;
                        this.showDrAstroNarration = true;
                        this.drAstroNarrationAnimation(0, this.Activity.states.nebula.Narration[1].content, 80, this.showDrAstroNarration, "dr-astro-narration-text-box");
                        setTimeout(() => {
                            setTimeout(() => {
                                this.show2BNarration = true;
                                this.toolHintsNarration(0, this.Activity.states.nebula.Narration[2].content, 80);
                                setTimeout(() => {
                                    this.toolbarStatus = '';
                                    this.currentToolClass == 'telescope';
                                    this.searchTools("Telescope", this.Activity.tools).status = "active";
                                    this.searchTools("Notebook", this.Activity.tools).status = "active";
                                }, (80 * this.Activity.states.nebula.Narration[2].content.length + 2000));                           
                            }, 1500)
                        },(80 * this.Activity.states.nebula.Narration[1].content.length + 2000));
                    }, ((80 * this.Activity.states.nebula.Narration[0].content.length)) + 6000);
                }, 2000);
            }else if(star == "star"){
                console.log("Star Clicked")
                this.currentTool.status = "disabled"
                this.currentToolClass = "Analyzer";
                this.currentTool.status = "disabled"
               
                // update state
                this.currentState = 'star';
                this.toolbarStatus = '';

                this.show2BNarration = true;
                this.toolbarStatus = 'toolbar-disabled';
                this.toolHintsNarrationTimeout1 = setTimeout(()=>{//2 to 3
                    this.toolHintsNarration(0, this.Activity.states.star.Narration[2].content, 80);
                    // update tool
                    setTimeout(()=>{
                        this.currentTool = this.searchTools("Analyzer", this.Activity.tools);
                        this.currentTool.status = "active";
                    }, 80 * this.Activity.states.star.Narration[2].content.length + 2000);
                }, 1000);
                setTimeout(()=>{
                    clearTimeout(this.toolHintsNarrationTimeout1);//2 too 3
                }, ((80 * this.Activity.states.star.Narration[2].content.length) + 8000));
            }
        },

        /** method to handle continue button action listener */
        continueBtnClick(state){
            if(state == 0){
                this.currentToolImage = "";
                this.currentToolClass = "";
                this.currentTool.status = "disabled"
                this.continueBtn = false;
                this.searchTools("Notebook", this.Activity.tools).status = "active";
                this.drAstroTimeOut = setTimeout(() => {
                    this.showDrAstroNarration = true;
                    this.drAstroNarrationAnimation(0, this.Activity.states.milkyway.Narration[5].content, 80, this.showDrAstroNarration, "dr-astro-narration-text-box");
                }, 1000);
                this.toolHintsNarrationTimeout5 = setTimeout(()=>{
                    this.showDrAstroNarration = false;
                    clearTimeout(this.drAstroTimeOut);
                    this.toolbarStatus = '';
                    this.nebulaActive = true;
                }, ((80 * this.Activity.states.milkyway.Narration[5].content.length) + 4000) );
            }else if(state == 1){
                this.currentToolImage = "";
                this.currentToolClass = "";
                this.currentTool.status = "disabled"
                this.continueBtn = false;
                this.toolbarStatus = '';
                this.nebulaActive = false;
                this.currentState = 'milkyway';
                this.drAstroTimeOut2 = setTimeout(() => {
                    this.showDrAstroNarration = true;
                    this.drAstroNarrationAnimation(0, this.Activity.states.star.Narration[0].content, 80, this.showDrAstroNarration, "dr-astro-narration-text-box");
                    setTimeout(() => {
                        this.showActivityTutorial = false;
                        this.showActivityIntro = false;
                        this.showPortal = false;
                        this.show2BNarration = true;
                        this.starActive = true;
                        this.toolHintsNarration(0, this.Activity.states.star.Narration[1].content, 80);
                    }, ((80*this.Activity.states.star.Narration[0].content.length) + 3000));

                }, 1000);
            }
        },
        customTimer(callback, delay) {
            var timerId, start, remaining = delay;

            this.pause = function() {
                window.clearTimeout(timerId);
                remaining -= Date.now() - start;
            };

            this.resume = function() {
                start = Date.now();
                window.clearTimeout(timerId);
                timerId = window.setTimeout(callback, remaining);
            };

            this.resume();
        },

        /** method to go to next activity */
        goToNextActivity(){
            this.$router.push({name: 'Activity', params: {id: this.$store.state.nextActivity.id, name: this.$store.state.nextActivity.name, lessonID: this.$route.params.lessonID}}).catch((err)=>{
                console.log("Error: " + err);
            });
        },

        /** to resize screen */
        screenResize(){
            if(document.getElementById('milky-way-container')){
                this.WrapperWidth = document.getElementById('milky-way-container').clientWidth;
            }
        },

    }
}
</script>

<style scoped>
.twoB-jumps {
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.second-background image,
.second-background {
    max-height: calc(100vh - 100px);
    position: relative;
}
.second-background {
    top: 50%;
    transform: translateY(-50%);
}
.milky-way-icons {
    max-width: 20%;
    position: absolute;
    max-height: 20%;
}
.milky-way-icons.star {
    left: 40%;
    bottom: 10px;
}
.milky-way-icons.nebula {
    left: 10%;
    bottom: 30%;
}
.milky-way-icons.solar-system {
    right: 0%;
    top: 10px;
}
.moveable.icon.drag {
    max-height: 100%;
    max-width: 100%;
    text-align: center;
}
.moveable.main.drop {
    width: 140px;
    height: 140px;
    position: absolute;
    z-index: 9;
    left: calc(50% - 70px);
    top: calc(50% - 70px);
}
.examiner-wrapper {
    position: relative;
}
.tool-icons:hover,
.tool-icons.in-use {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}
.tool-icons.disabled:hover {
    background-color: transparent;
    cursor: not-allowed;
}
.currentToolImage, .notebookToolImage {
    position: absolute;
    width: 100%;
    max-height: calc(100vh - 100px);
    z-index: 9;
}
.currentToolImage img, .notebookToolImage img {
    max-width: 100%;
    max-height: calc(100vh - 150px);
    width: 100%;
}
.currentToolImage.measuring-tape-opened {
    right: 10px;
    width: 120%;
    top: 50%;
    transform: translateY(-60%);
}
.currentToolImage.measuring-tape-opened image {
    width: 100%;
    -webkit-clip-path: inset(0% 76.5% 0% 0%);
    clip-path: inset(0% 76.5% 0% 0%);
    transition: all 0.5s;
}
.currentToolImage.measuring-tape-opened .opened image {
    -webkit-clip-path: inset(0% 0% 0% 0%);
    clip-path: inset(0% 0% 0% 0%);
}
.currentToolImage.measuring-tape-opened .opened:before {
    content: '105,700 Light Years';
    /* font-family: 'Lomo', sans-serif; */
    font-size:28px;
    color: #fff;
    position: absolute;
    right: 0;
    top: calc(60% - 10px);
}

.notebookToolImage.digital-notebook {
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
}

.jet-pack {
    position: fixed;
    bottom: 5px;
    left: 30px;
    max-width: 200px;
}
.jet-pack img {
    width: 100%;
}
.jet-pack.fly {
    left: 30px;
    animation: fly 5s ease-in-out;
    -webkit-animation: fly 5s ease-in-out;
    -moz-animation: fly 5s ease-in-out;
    -o-animation: fly 5s ease-in-out;
    z-index: 900;
}
.jet-pack.bounce {
    left: 30px;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
    z-index: 900;
}

@keyframes fly {
    0% {
        bottom: 5px;
        left: calc(100vw - 230px);
        transform: rotate(0deg);
    }
    30% {
        bottom: calc(100vh - 220px);
        left: calc(100vw - 230px);
        transform: rotate(-90deg);
    }
    70% {
        bottom: calc(100vh - 220px);
        left: 30px;
        transform: rotate(0deg);
    }
    100% {
        bottom: 5px;
        left: 30px;
        transform: rotate(0deg);
    }
}
@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-30px);
    }
}

/* Blink bulb Animation */
@keyframes blinkBulb {
    0% {
        filter: brightness(500%);
        fill: rgb(255, 255, 0);
        fill-opacity: 0.8;
    }
    50% {
        filter: brightness(0.1);
        fill: rgb(112, 107, 107);
        fill-opacity: 0.1;
    }
    100% {
        filter: brightness(500%);
        fill: rgb(255, 255, 0);
        fill-opacity: 0.8;
    }
}
.pathBulb{
    animation: blinkBulb 1s;
    animation-iteration-count: infinite;
}

/* Blink Animation */
@keyframes blink {
    0% {
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }
    50% {
        filter: drop-shadow(0 0 0 #ffffff);
    }
    100% {
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }
}

#myVideo {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(95vw);
    max-height: calc(95vh);
    border: 5px solid rgba(255, 255, 255, 1);
    border-style: double;
    transition: all 0.3s;
}
.intro-video-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
}
.currentToolImage.telescope img,
img.telescope-zoomed {
    position: fixed;
    right: 30px;
    bottom: -50px;
    max-width: 200px;
    max-height: 200px;
    z-index: 910;
}

.digital-notebook-textarea.digital-notebook {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    padding: 25% 15% 20% 15%;
    z-index: 300;
}
.digital-notebook-textarea.digital-notebook textarea {
    width: 100%;
    height: 100%;
    resize: none;
    outline: none;
    color: #fff;
}
.currentToolImage.analyzer {
    top: 50%;
    transform: translateY(-50%);
}
.currentToolImage.analyzer.star-tool::after {
    position: absolute;
    bottom: 20%;
    color: #fff;
    left: 20%;
    font-family: 'Lomo', sans-serif;
    content: 'The star is really made up of gases collapsing in on themselves and it is due to gravity.';
    max-width: 60%;
}
.back-btn,
.back-icon {
    position: fixed;
    left: 15px;
    top: 30px;
    color: #fff;
    font-size: 46px;
    transition: all 0.3s;
    cursor: pointer;
    -webkit-text-stroke: 1px black;
}
.back-btn:hover,
.back-icon:hover {
    text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
    color: #6fc41e;
}
.nebula-zoom {
    position: fixed;
    max-width: 100vw;
    max-height: 100vh;
    z-index: 905;
}
.nebula-zoom-1{
    right: 0;
    bottom: 0;
}
.nebula-zoom-2{
    left: 0;
    top: 0;
}
.nebula-zoom-container{
    background: #181818;
    width: calc(100vw);
    height: calc(100vh);
    left: 0;
    top: 0;
    position: fixed;
    z-index: 901;
}

.exit-full-screen {
    position: fixed;
    left: 30px;
    top: 30px;
    z-index: 910;
    color: #fff;
    cursor: pointer;
    font-size: 40px;
    animation: blink 1s;
    animation-iteration-count: infinite;
}
.exit-full-screen:hover {
    color: #6fc41e;
}
.analyzer-gif {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
}
.dr-astro-narration {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 990;
}
.dr-astro {
    height: 400px;
    width: 350px;
    position: fixed;
    right: 30px;
    bottom: -25px;
    max-width: 200px;
    max-height: 200px;
}
.dr-astro .v-image {
    position: initial;
}
#dr-astro-narration {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    font-family: 'Lomo', sans-serif;
    left: 50%;
    width: calc(60vw);
    max-height: calc(90vh);
    overflow-y: auto;
    /* padding: 30px 15px; */
    padding: 2.1em;
    border-radius: 50px;
    text-align: left;
}
.slide-fade-enter-active {
    transition: all 1s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(300px);
    opacity: 0;
}
.hint-narration {
    background-color: #fff;
    border-radius: 30px;
    padding: 15px;
    position: fixed;
    max-width: 200px;
    bottom: 225px;
    left: 30px;
    line-height: 1.2;
    z-index: 1000;
}
.toolbar-disabled:hover {
    cursor: not-allowed !important;
}
.toolbar-disabled {
    pointer-events: none !important;
}

.bg-disabled {
    /* cursor: not-allowed; */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 10000 !important;
}

.instruction-wrapper {
    background: #ffffff;
    color: #000000;
    text-align: left !important;
    min-width: 67vw;
    overflow-y: auto;
    border-radius: 50px;
    position: fixed;
    padding: 2em;
    left: 50%;
    top: 50%;
    font-family: 'Lomo', sans-serif;
    transform: translate(-50%, -50%);
}

.instruction-wrapper p {
    letter-spacing: 2px;
    word-spacing: 5px;
    margin: 0;
    line-height: 1.7;
}

@media screen and (max-width: 767px) {
    /* .toolbar{
        width: calc(100vw);
        padding: 10px 10px 10px 10px;
    } */
    .examiner-wrapper {
        height: calc(100vh - 100px);
        margin: auto;
    }
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
    /* .toolbar{
        width: calc(80vw);
        left: calc(50% - 40vw);
        padding: 10px 10px 10px 10px;
    } */
    .examiner-wrapper {
        height: calc(100vh - 100px);
        margin: auto;
    }
}
@media screen and (min-width: 1001px) {
    /* .toolbar{
        width: calc(50vw);
        left: calc(50% - 25vw);
        padding: 10px 10px 10px 10px;
    } */
    .examiner-wrapper {
        height: calc(100vh - 100px);
        margin: auto;
    }
}

@media screen and (max-width: 600px) {
}
@media screen and (max-width: 601px) and (min-width: 767px) {
}
@media screen and (max-width: 959px) and (min-width: 768px) {
}
@media screen and (max-width: 1263px) and (min-width: 960px) {
}
@media screen and (min-width: 1264px) {
}
</style>
