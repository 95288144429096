<template>
    <v-container fluid v-bind:style="{ 'background-image': 'url(' + bgImg + ')'}" class="bg-container">

    </v-container>
</template>

<script>
import background from '@/assets/img/activities/activity_10/night-beach-stars.png';

export default {
    name: 'Activity8ExploringStars',

    data() {
        return {
            Activity: {
                id: '021',
                name: 'Exploring The Stars',
            },
            bgImg: background,
        };
    },
};
</script>

<style scoped>
.bg-container {
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
}

</style>
