<!-- 
    This component will render tools at bottom-center of the screen.
    To handle tool click, implement toolClick method in parent component

    @author: Muhammad Mansha

-->
<template>
    <transition name="fade-transition">
        <template>
            <div class="toolbar">
                <v-row justify="center">   
                    <v-col cols="12" class="percent14 tool-icons" v-for="item in tools" :key="item.name" :class="[item.status, item.class]"  v-on:click="$emit('toolClick', item.tool_main_img, item.class, item)">
                        <v-img :src="require(`@/assets/img/activities/activity_1a/${item.icon_url}`)" contain></v-img>
                    </v-col>
                </v-row>
            </div>
        </template>
    </transition>
</template>

<script>
export default {
    name: "Tools", 
    props: ["tools"]  
}
</script>

<style scoped>
    .percent14.col-12{
        flex: 0 0 14.2857143%;
        max-width: 16.6666%;
        transition: background-color 0.3s;
    }
    
    .toolbar{
        max-height: 100px;
        position: fixed;
        bottom: 0;
        background-color: rgba(255,255,255, 0.25);
        z-index: 900;
    }

    .toolbar .row .col-12{
        display: flex;
        max-height: 100px;
    }

    .toolbar .row .col-12.disabled{
        opacity: 0.4;
        pointer-events: none;
    }

    .tool-icons.active:hover, .tool-icons.in-use{
        background-color: rgba(255,255,255,0.5);
        cursor: pointer;
    }

    .tool-icons.active:not(.active.digital-notebook) {
        animation: blink 1s;
        animation-iteration-count: infinite;
    }
    
    /* Blink Animation */
    @keyframes blink {
        0% {
            filter: drop-shadow(0 0 0.75rem #ffffff);
            /* opacity: 1; */
        }
        50% {
            filter: drop-shadow(0 0 0 #ffffff);
            /* opacity: 0.5; */
        }
        100% {
            filter: drop-shadow(0 0 0.75rem #ffffff);
            /* opacity: 1; */
        }
    }

    .tool-icons.disabled:hover {
        background-color: transparent;
        cursor: not-allowed !important;
    }

    .toolbar .toolbar-disabled{
        pointer-events: none !important;
        cursor: not-allowed !important;
    }

    /* .currentToolImage{
        position: absolute;
        width: 100%;
        max-height: calc(100vh - 100px);
        z-index: 9;
    }

    .currentToolImage img{
        max-width: 100%;
        max-height: calc(100vh - 150px);
        width: 100%;
    } */

    @media screen and (max-width: 1000px) and (min-width: 768px){
        .toolbar{
            width: calc(80vw);
            left: calc(50% - 40vw);
            padding: 10px 10px 10px 10px;
        }
    }
    @media screen and (min-width: 1001px){
        .toolbar{
            width: calc(50vw);
            left: calc(50% - 25vw);
            padding: 10px 10px 10px 10px;
        }
    }


</style>