var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main-container",attrs:{"fluid":""}},[_c('v-container',{staticClass:"bg-container",style:({ 'background-image': 'url(' + _vm.bgImg + ')' }),attrs:{"fluid":""}},[(_vm.showStarAnimation)?_c('span',{staticClass:"star",attrs:{"id":"starAnimation"}},[_c('img',{attrs:{"src":require("@/assets/img/activities/activity_10/star.png")}})]):_vm._e(),_c('v-row',[_c('CountUpTimer',{ref:"assessmentTimeUpTimer",staticClass:"timer"})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{ref:"backIcon",staticClass:"back-icon",on:{"click":function($event){return _vm.$router.push({
                            name: 'Lessons',
                            params: {
                                id: '6a6b5c5d9e9',
                                name: 'Earth and Space',
                                slug: 'earth-and-space',
                            },
                        })}}},[_vm._v(" west ")]),_c('transition',{attrs:{"name":"fade-transition"}},[_c('div',{staticClass:"two-b"},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showTwoB)?_c('v-img',{attrs:{"src":_vm.twoBImg,"width":"250","height":"300","eager":"","contain":""}}):_vm._e()],1)],1)])],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('transition',{attrs:{"name":"fade-transition"}},[_c('div',{class:[_vm.showNarration ? 'dr-astro-narration' : '']},[_c('transition',{attrs:{"name":"fade-transition"}},[_c('div',{class:[
                                    _vm.showDrAstroLeft
                                        ? 'dr-astro-left'
                                        : 'dr-astro' ]},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showDrAstro)?_c('v-img',{attrs:{"src":_vm.drAstroImg,"eager":"","contain":""}}):_vm._e()],1),_c('transition',{attrs:{"name":"scale-transition"}},[(_vm.showNarration)?_c('div',{attrs:{"id":[
                                            _vm.showDrAstroLeft
                                                ? 'dr-astro-narration-left'
                                                : 'dr-astro-narration' ]}},[_c('p',{attrs:{"id":"dr-astro-narration-text-box"}},[_vm._v(" "+_vm._s(_vm.drAstroNarrationText)+" ")])]):_vm._e()])],1)])],1)])],1)],1),_c('v-row',[_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.showStarVideo)?_c('div',{staticClass:"stars-video-wrapper"},[_c('video',{attrs:{"muted":"","autoplay":"","id":"starsVideo"},domProps:{"muted":true},on:{"ended":_vm.starsVideoEnded,"loadeddata":_vm.checkLoading}},[_c('source',{attrs:{"src":_vm.videoURL(),"type":"video/mp4"}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.videoLoading && _vm.showStarVideo)?_c('div',{staticClass:"full-screen-loader"},[_c('v-progress-circular',{staticClass:"progressLoader",attrs:{"indeterminate":"","color":"#000000"}})],1):_vm._e()]),_c('v-row',[_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.nextActivityBtn)?_c('v-btn',{staticClass:"skip custom-font next-activity-btn",on:{"click":_vm.goToNextActivity}},[_vm._v("Next Activity")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }