<template v-if="show">
    <v-container fluid fill-height v-bind:style="{ 'background-image': 'url(' + require(`@/assets/img/`+currentBackground) + ')', 'background-size': 'cover' }" :class="Activity.class">
        <transition name="fade-transition">
            <!-- <v-img class="twoB-jumps" @load="videoEnded" v-if="showPortalAnimation" :src="require(`@/assets/img/activities/activity_KSL/${portalAnimation}`)"></v-img> -->
            <video autoplay muted id="myVideo" @ended="videoEnded" v-if="showPortalAnimation" @loadeddata="checkLoading">
                <source :src="require(`@/assets/videos/activities/activity_ksl/${portalAnimation}`)" type="video/mp4">
            </video>
        </transition>
        <transition name="fade-transition">
            <div class="full-screen-loader" v-if="videoLoading || !show">
                <v-progress-circular
                    indeterminate
                    color="#000000"
                    class="progressLoader">
                </v-progress-circular>
            </div>
        </transition>
        <transition>
            <div v-if="shuffleActivityBtn" @click="shuffleActivity" class="skip custom-font">Start</div>
        </transition>
        <transition name="fade-transition">
            <v-container class="key-science-lingo" v-if="!showPortalAnimation && portalAnimationWatched && !ActivityCompleted" :class="[Activity.class, Activity.type]">
                <v-row class="mt-13">
                    <v-col cols="12" sm="3" align-self="end" v-if="Activity.type == 'key-science-lingo-right'">
                        <div class="activity-2b" :class="ActivityCompletedClass">
                            <v-img src="@/assets/2b-Happy-talk.png" eager contain></v-img>
                            <div v-if="twoBnarration" id="twoBnarration" v-bind:style="{'top': narrationTop+'px'}">{{twoBnarration}}</div>
                        </div>
                        <v-icon class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
                            west
                        </v-icon>
                    </v-col>
                    <transition name="fade-transition">
                        <v-col cols="12" sm="3" class="drag-drop" :class="ActivityCompletedClass" v-if="showShuffled">
                            <div>
                                <template v-for="(item,index) in Activity.assets">
                                    <div 
                                        :key="index"
                                        class="moveable question drag"
                                        :draggable="item.draggable"
                                        @dragstart='startDrag($event, item, index)'
                                        :class="item.class"
                                    >
                                        <h2>{{item.question}}</h2>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </transition>
                    <transition name="fade-transition">
                        <v-col cols="12" sm="6" class="drag-drop" :class="ActivityCompletedClass" v-if="showShuffled">
                                <div>
                                    <transition-group name="flip-list" tag="ul">
                                        <li v-for="(item, index) in ActivityShuffled" :key="item.answer">
                                            <div
                                                :key="index"
                                                class="moveable answer drop"
                                                @drop='onDrop($event, Activity.assets.find(e => e.question == item.question), index)' @dragover.prevent @dragenter.prevent
                                                :class="item.class"
                                            >
                                                <p>{{item.answer}}</p>
                                            </div>
                                        </li>
                                    </transition-group>
                                </div>
                            
                            <transition name="fade-transition">
                                <template>
                                    <CountUpTimer v-if="showTimer" ref="assessmentTimeUpTimer" :coins="assessmentCoins" class="timer"></CountUpTimer>
                                </template>
                            </transition>
                            <v-icon class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
                                west
                            </v-icon>
                        </v-col>
                    </transition>
                    <transition name="fade-transition">
                        <v-col cols="12" sm="9" v-if="!showShuffled" class="un-shuffled">
                            <template v-for="(item,index) in Activity.assets">
                                <transition :key="index" name="fade-transition">
                                    <v-row :key="index" no-gutters v-if="showPuzzle">
                                        <v-col cols="12" sm="4">
                                            <div
                                                class="moveable question drag"
                                                :draggable="item.draggable"
                                                @dragstart='startDrag($event, item, index)'
                                                :class="item.class"
                                            >
                                                <h2>{{item.question}}</h2>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                            <div
                                                class="moveable answer drop"
                                                @drop='onDrop($event, Activity.assets.find(e => e.question == item.question), index)' @dragover.prevent @dragenter.prevent
                                                :class="item.class"
                                            >
                                                <p>{{item.answer}}</p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </transition>
                            </template>
                        </v-col>
                    </transition>
                    <v-col cols="12" sm="3" align-self="end" v-if="Activity.type == 'key-science-lingo-left'">
                        <div class="activity-2b" :class="ActivityCompletedClass">
                            <v-img src="@/assets/2b-Jetpack.png" eager contain :class="twoBClass" class="jet-pack"></v-img>
                            <div v-if="twoBnarration" id="twoBnarration" v-bind:style="{'top': narrationTop+'px'}">{{twoBnarration}}</div>
                        </div>
                        <v-icon class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
                            west
                        </v-icon>
                    </v-col>
                </v-row>
            </v-container>
        </transition>
    </v-container>
</template>
<script>
import CountUpTimer from '@/components/CountUpTimer';
const _ = require("lodash"); 

export default {
    name: 'ActivityKSL',
    components: {
        CountUpTimer
    },
    data(){
        return{
            portalAnimation: '',
            showPortalAnimation: false,
            portalAnimationWatched: false,
            twoBnarration: '',
            currentBackground: '',
            narrationSpeed: 120,
            narrationLetterIndex: 0,
            ActivityCompleted: false,
            ActivityCompletedClass: '',
            videoLoading: false,
            TyperwritingNarrationStaticInterval: null,
            twoBClass: '',
            shuffleActivityBtn: false,
            trigger: 0,
            show: false,
            showTimer: false,
            showPuzzle: false,
            narrationTop: 0,
            helpText: 'Click on "Start" to begin the activity.',
            showShuffled: false,
            audio: new Audio(require(`@/assets/audio/shuffle-sound.mp3`)),
        }
    },
    mounted(){
        this.assessmentCoins = 0;
    },
    destroyed(){
        this.$store.state.Activity = null;
    },
    created(){
        this.currentBackground = this.$store.state.Activity.starting_background;
        this.$store.state.Activity.assets.forEach(element => {
            element.status = '';
            element.class = '';
            element.draggable = false;
        });
        this.ActivityShuffled = [];
        let temp  = [...this.$store.state.Activity.assets];
        temp.forEach(element => {
            this.ActivityShuffled.push({...element})
        });
        if(this.Activity.starting_animation){
            this.videoLoading = true;
            this.portalAnimation = this.Activity.starting_animation;
            this.showPortalAnimation = true;
        }
        else{
            this.portalAnimationWatched = true;
        }
        this.show = true;
    },
    methods:{
        play() {
            //var audio = new Audio(require(`@/assets/audio/${sound}.mp3`));
            this.audio.play()
            .then(() => {
                console.log("Audio is playing");
            })
            .catch(error => {
                console.log("error:" +error);
            });
        },
        shuffleActivity(){
            this.videoLoading = true;
            this.showShuffled = true;
            this.shuffleActivityBtn = false
            var _this = this;
            _this.twoBnarration = '';
            _this.narrationLetterIndex = 0;
            setTimeout(() => {
                _this.videoLoading = false;
                setTimeout(() => {
                    _this.play();
                    _this.ActivityShuffled = _.shuffle(this.ActivityShuffled);
                    _this.Activity.assets.forEach(element => {
                        element.draggable = true;
                    });
                    setTimeout(() => {
                        _this.showTimer = true;
                        if(_this.Activity.hint){
                            _this.TyperwritingNarrationStatic(_this.Activity.hint, 'hint');
                        }
                    }, 1000);
                }, 1000);
            }, 1000);
        },
        startDrag(evt, item, index){
            evt.dataTransfer.dropEffect = 'move';
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('item', JSON.stringify(item));
            evt.dataTransfer.setData('index', index);
        },
        onDrop(evt, original_item, index) {
            var _this = this, totalActivityTime;
            const item = JSON.parse(evt.dataTransfer.getData('item'));
            const original_index = evt.dataTransfer.getData('index');
            if(_this.$store.state.ActivityShuffled[index].class != 'green'){
                if(item.question != original_item.question){
                    if(_this.$store.state.Activity.assets[original_index].status == '' || _this.$store.state.Activity.assets[original_index].status == undefined || _this.$store.state.Activity.assets[original_index].status == null){
                        _this.$store.state.Activity.assets[original_index].status = 'wrong';
                    }
                    _this.$store.state.Activity.assets[original_index].draggable = false;
                    _this.$store.state.Activity.assets[original_index].class = 'red';
                    _this.$store.state.ActivityShuffled[index].class = 'red';
                    _this.trigger++;
                    setTimeout(() => {
                        _this.$store.state.Activity.assets[original_index].class = '';
                        if(_this.$store.state.ActivityShuffled[index].class == 'red'){
                            _this.$store.state.ActivityShuffled[index].class = '';
                        }
                        _this.$store.state.Activity.assets[original_index].draggable = true;
                        _this.trigger++;
                    }, 2000);
                }
                else{
                    if(_this.$store.state.Activity.assets[original_index].status == '' || _this.$store.state.Activity.assets[original_index].status == undefined || _this.$store.state.Activity.assets[original_index].status == null){
                        _this.$store.state.Activity.assets[original_index].status = 'correct';
                        _this.assessmentCoins++;
                    }
                    _this.$store.state.Activity.assets[original_index].draggable = false;
                    _this.$store.state.Activity.assets[original_index].class = 'green';
                    _this.$store.state.ActivityShuffled[index].class = 'green';
                    _this.trigger++;
                    if(this.Activity.assets.filter(e=> e.class == 'green').length == this.Activity.assets.length){
                        totalActivityTime = _this.$refs.assessmentTimeUpTimer.minutes + ":" + _this.$refs.assessmentTimeUpTimer.seconds;    
                        _this.$store.state.activityTime = totalActivityTime;
                        this.ActivityCompletedClass = 'ActivityCompleted';
                        clearTimeout(this.TyperwritingNarrationStaticInterval)
                        this.twoBnarration = '';
                        if(_this.Activity.ending_background){
                            _this.currentBackground = _this.Activity.ending_background;
                        }
                        setTimeout(() => {
                            _this.ActivityCompleted = true;
                            if(_this.Activity.ending_animation){
                                _this.portalAnimation = _this.Activity.ending_animation;
                                _this.showPortalAnimation = true;
                                _this.videoLoading = true;
                            }
                            else{
                                _this.videoEnded()
                            }
                        }, 3000);
                    }
                }
            }
        },
        activityCompleted(){
            this.twoBnarration = '';
            this.narrationLetterIndex= 0;
            var _this = this;
            this.showPortalAnimation = false;
            this.$emit('completed');
            if(this.Activity.type == 'key-science-lingo-right'){
                setTimeout(() => {
                    _this.$router.push({name: 'ActivityScoreboard', params: {activityID: this.$route.params.id, lessonID: this.$route.params.lessonID}});
                }, 2000);
            }
            else{
                _this.$router.push({name: 'ActivityScoreboard', params: {activityID: this.$route.params.id, lessonID: this.$route.params.lessonID}});
            }
        },
        /* videoEnded(){
            this.videoLoading = false;
            var _this = this;
            setTimeout(() => {
                _this.showPortalAnimation = false;
                _this.portalAnimationWatched = true
                if(_this.portalAnimation == _this.Activity.starting_animation){
                    if(_this.Activity.narration_2b){
                        if(_this.Activity.type == 'key-science-lingo-left'){
                            _this.twoBClass = 'fly';
                            setTimeout(() => {
                                _this.showPuzzle = true;
                                _this.twoBClass = 'bounce';
                                _this.showNarration= true;
                                setTimeout(() => {
                                    _this.TyperwritingNarration();
                                }, 10);
                            }, 6000);
                        }
                        else{
                            _this.showPuzzle = true;   
                            _this.showNarration= true;
                            setTimeout(() => {
                                _this.TyperwritingNarration();
                            }, 10);
                        }
                        
                    }
                    else{
                        if(_this.Activity.type == 'key-science-lingo-left'){
                            _this.twoBClass = 'fly';
                            setTimeout(() => {
                                _this.showPuzzle = true; 
                                _this.twoBClass = 'bounce';
                                _this.shuffleActivityBtn = true;
                            }, 6000);
                        }
                        else{   
                            _this.showPuzzle = true;
                            _this.shuffleActivityBtn = true;
                        }
                    }
                }
                else{
                    _this.activityCompleted()
                }
            }, 2220);
        }, */
        videoEnded(){
            var _this = this;
            _this.showPortalAnimation = false;
            _this.portalAnimationWatched = true
            if(_this.portalAnimation == _this.Activity.starting_animation){
                if(_this.Activity.narration_2b){
                    if(_this.Activity.type == 'key-science-lingo-left'){
                        _this.twoBClass = 'fly';
                        setTimeout(() => {
                            _this.showPuzzle = true;
                            _this.twoBClass = 'bounce';
                            _this.showNarration= true;
                            setTimeout(() => {
                                _this.TyperwritingNarration();
                            }, 10);
                        }, 6000);
                    }
                    else{
                        _this.showPuzzle = true;   
                        _this.showNarration= true;
                        setTimeout(() => {
                            _this.TyperwritingNarration();
                        }, 10);
                    }
                    
                }
                else{
                    if(_this.Activity.type == 'key-science-lingo-left'){
                        _this.twoBClass = 'fly';
                        setTimeout(() => {
                            _this.showPuzzle = true; 
                            _this.twoBClass = 'bounce';
                            _this.shuffleActivityBtn = true;
                        }, 6000);
                    }
                    else{   
                        _this.showPuzzle = true;
                        _this.shuffleActivityBtn = true;
                    }
                }
            }
            else{
                _this.activityCompleted()
            }
        },
        TyperwritingNarration(){
            var _this = this;
            this.narrationTop = -(this.twoBnarration.length+100)
            setTimeout(()=>{
                if (this.narrationLetterIndex < _this.Activity.narration_2b.length) {
                    _this.twoBnarration += _this.Activity.narration_2b.charAt(_this.narrationLetterIndex);
                    _this.narrationLetterIndex++;
                    setTimeout(_this.TyperwritingNarration(), _this.narrationSpeed);
                }
                else{
                    setTimeout(()=>{
                        _this.twoBnarration = '';
                        _this.narrationLetterIndex = 0;
                        _this.TyperwritingNarrationStatic(_this.helpText, 'help');
                    },4000);
                }
            },_this.narrationSpeed);
        },
        TyperwritingNarrationStatic(narration, action){
            var _this = this;
            this.narrationTop = -(this.twoBnarration.length+100)
            this.TyperwritingNarrationStaticInterval = setTimeout(()=>{
                if (_this.narrationLetterIndex < narration.length) {
                    _this.twoBnarration += narration.charAt(_this.narrationLetterIndex);
                    _this.narrationLetterIndex++;
                    setTimeout(_this.TyperwritingNarrationStatic(narration, action), _this.narrationSpeed);
                }
                else if(action == 'help'){  
                    _this.shuffleActivityBtn = true;
                }
            },_this.narrationSpeed);
        },
        checkLoading(){
            this.videoLoading = false;
        }
    },
    computed:{
        Activity:{
            set(value){
                this.$store.state.Activity = value;
            },
            get(){
                console.log(this.trigger);
                return this.$store.state.Activity;
            }
        },
        assessmentCoins:{
            get(){
                return this.$store.state.assessmentCoins;
            },
            set(value){
                this.$store.state.assessmentCoins =value;
            }
        },
        ActivityShuffled:{
            get(){
                return this.$store.state.ActivityShuffled
            },
            set(value){
                console.log(this.trigger);
                this.$store.state.ActivityShuffled = value;
            }
        }
    }
}
</script>
<style scoped>
#myVideo {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(95vw);
    max-height: calc(95vh);
    border: 5px solid rgba(255,255,255,1);
    border-style: double;
    transition: all 0.3s;
}

.key-science-lingo .moveable{
    cursor: grab;
    width: max-content;
    padding: 0px 15px;
    margin: 15px 0px;
    border-radius: 10px;
    transition: all 0.3s;
}
.key-science-lingo .un-shuffled .row {
    align-items: center;
    margin-bottom: 5px;
}
.key-science-lingo .un-shuffled .row .moveable{
    margin: 5px 0px;
    
}
.key-science-lingo .un-shuffled .row .moveable.question{
    margin-left: auto;
    margin-right: 15px;
}
.key-science-lingo .moveable:active{
    cursor: grabbing;
}
.key-science-lingo .un-shuffled .moveable{
    cursor: auto;
}
.key-science-lingo-1 .moveable{
    background-color: #F9E5B0;
    border: 10px outset #F9E5B0;
    box-shadow: 0px 0px 10px 5px #8ca166;
}
.key-science-lingo-2 .moveable{
    background-color: #B5E7E8;
    border: 10px outset #B5E7E8; 
    /* box-shadow: 0px 0px 10px 5px #B5E7E8; */
}
.key-science-lingo .moveable.green, .key-science-lingo .moveable.red, .key-science-lingo .moveable.green h2, .key-science-lingo .moveable.red h2{
    color: #fff;
}
.key-science-lingo .moveable.drop{
    width: 100%;
}
.key-science-lingo .moveable h2{
    color: #000;
    font-family: Impact, fantasy;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1px;
}
.key-science-lingo .moveable p{
    margin-bottom: 6px;
    font-weight: 500;
}
.key-science-lingo .moveable.question{
    margin-bottom: 25px;
    font-weight: 400;
    padding-bottom: 5px;
    line-height: 1;
}
.key-science-lingo .moveable.answer{
    max-width: 550px;
}

.key-science-lingo-right #twoBnarration{
    position: absolute;
    top: -140px;
    background-color: #fff;
    left: -60px;
    width: 210px;
    padding: 30px 25px;
    border-radius: 50%;
    text-align: center;
}
.key-science-lingo-right #twoBnarration:before{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 45px;
    bottom: -10px;
    border: 10px solid;
    border-color: #fff #fff transparent transparent;
}
.key-science-lingo-left #twoBnarration{
    position: absolute;
    top: -150px;
    background-color: #fff;
    left: 60px;
    width: 210px;
    padding: 30px 25px;
    border-radius: 50%;
    text-align: center;
}
.key-science-lingo-left #twoBnarration:before{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 55px;
    right: auto;
    bottom: -10px;
    border: 10px solid;
    border-color: #fff transparent transparent #fff;
}
.key-science-lingo .col-12{
    position: relative;
}
.drag-drop.ActivityCompleted{
    display: none;
}
.activity-2b.ActivityCompleted {
    position: fixed;
    width: calc(25vw);
    top: 40%;
    left: 40%;
    animation: bounce_joy 2s infinite;
    -webkit-animation: bounce_joy 2s infinite;
    -moz-animation: bounce_joy 2s infinite;
    -o-animation: bounce_joy 2s infinite;
}
.twoB-jumps{
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.back-btn, .back-icon{
    position: fixed;
    left: 15px;
    top: 30px;
    color: #fff;
    font-size: 46px;
    transition: all 0.3s;
    cursor: pointer;
    -webkit-text-stroke: 1px black;
}
.back-btn:hover, .back-icon:hover{
    text-shadow: 2px 2px 2px rgba(255,255,255,0.5);
    color: #6FC41E;
}
@keyframes bounce_joy {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
}
.jet-pack.fly{
    right: 30px;
    animation: fly 5s ease-in-out;
    -webkit-animation: fly 5s ease-in-out;
    -moz-animation: fly 5s ease-in-out;
    -o-animation: fly 5s ease-in-out;
    z-index: 900;
}
.jet-pack.bounce{
    right: 30px;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
    z-index: 900;
}
.flip-list-move {
  transition: transform 0.7s ease;
}
.drag-drop ul li{
    list-style: none;
}
@keyframes fly{
    0%{
        bottom: 5px;
        right: calc(100vw - 35vw);
        transform: rotate(0deg);
    }
    30%{
        bottom: calc(100vh - 220px);
        right: calc(100vw - 35vw);
        transform: rotate(90deg);
    }
    70%{
        bottom: calc(100vh - 220px);
        right: 30px;
        transform: rotate(0deg);
    }
    100%{
        bottom: 5px;
        right: 30px;
        transform: rotate(0deg);
    }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}
@media screen and (min-width: 768px) {
    .activity-2b{
        transition: all 1s;
        position: fixed;
        max-width: 200px;
        bottom: 10px;
    }
}
@media screen and (min-width: 768px) and (max-width: 960px) {
    #twoBnarration{
        top: -125px;
        left: 10px;
        width: 200px;
    }  
    #twoBnarration:before{
        left: 45px;
        right: auto;
    }
}
</style>