<template>
    <v-container fluid fill-height v-bind:style="{ 'background-image': 'url(' + require(`@/assets/img/`+Activity.background) + ')' }">
        <v-container>
            <v-row>
                <v-col cols="12" sm="2">
                    <transition name="fade-transition">
                        <div v-if="!AstroIntroWatched || galaxyPartsNarrationPlaying" class="dr-astro-narration" :class="narrationClass">
                            <transition name="fade-transition">
                                <div class="dr-astro">
                                    <transition name="slide-fade">
                                        <v-img src="@/assets/img/activities/activity_1a/dr-astro.png" width="350" height="400" v-if="showDrAstro" eager contain></v-img>
                                    </transition>
                                    <transition name="scale-transition">
                                        <div id="dr-astro-narration" v-if="showNarration">{{drAstroNarration}}</div>
                                    </transition>
                                </div>
                            </transition>
                        </div>
                    </transition>
                    <h3 v-if="AstroIntroWatched" class="remaining-attempts">Remaining Attempts: {{2 - this.wrongCount}}</h3>
                </v-col>
                <v-col cols="12" sm="8" id="milkway-svg-wrapper">
                    <transition name="fade-transition">
                        <img id="galaxy-main-img" :src="galaxyMainImg">
                    </transition>
                    <transition name="scale-transition">
                        <div class="correct-animation" v-if="showCorrectAnimation">
                            <p>Correct!</p>
                        </div>
                    </transition>
                    <transition name="fade-transition">
                        <template>
                            <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 765 640" class="second-background" style="opacity: 0;" id="galaxy-secondary-img">
                            <image width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_2/${currentSecondaryImg}`)"></image> 
                            
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_center"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="336" y="236" fill="#fff" opacity="0" width="124" height="128"></rect>
                                </a>

                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_star"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="339" y="488" fill="#fff" opacity="0" width="73" height="81"></rect>
                                </a>

                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_solar_system"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="592" y="148" fill="#fff" opacity="0" width="120" height="72"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_nebula"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="0" y="408" fill="#fff" opacity="0" width="132" height="113"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="68" y="0" fill="#fff" opacity="0" width="261" height="401"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="326" y="0" fill="#fff" opacity="0" width="258" height="228"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="466" y="228" fill="#fff" opacity="0" width="299" height="378"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="141" y="396" fill="#fff" opacity="0" width="189" height="207"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="328" y="372" fill="#fff" opacity="0" width="141" height="108"></rect>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <rect x="420" y="475" fill="#fff" opacity="0" width="50" height="100"></rect>
                                </a>
                            </svg> -->
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 765 640" class="second-background" style="opacity: 0;" id="galaxy-secondary-img" v-bind:style="{'width':svgWidth+'px', 'height': svgHeight+'px'}">
                            <image width="765" height="640" :xlink:href="require(`@/assets/img/activities/activity_2/${currentSecondaryImg}`)"></image> 
                            
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_center"))' @dragover.prevent @dragenter.prevent>
                                    <path d="M357,302,374,273,388,255,415,230,430,238,453,240,463,258,447,285,423,323,403,357,391,367,362,359,345,366,328,348z" fill="green" transform="translate(0,0)"/>
                                    <!-- <rect x="336" y="236" fill="#fff" opacity="0" width="124" height="128"></rect> -->
                                </a>

                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_star"))' @dragover.prevent @dragenter.prevent>
                                    <path d="M347,539,369,555,374,563,387,572,407,574,419,556,413,533,390,499,373,487,351,485,338,493,336,511z" fill="green" transform="translate(0,0)"/>
                                </a>

                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_solar_system"))' @dragover.prevent @dragenter.prevent>
                                    <path d="M597,223,641,213,669,213,694,212,709,210,716,191,715,161,710,140,687,143,659,149,643,144,628,141,609,159,587,174z" fill="green" transform="translate(0,0)"/>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_nebula"))' @dragover.prevent @dragenter.prevent>
                                    <path d="M56,522,103,527,136,507,135,465,132,436,101,422,76,412,43,404,16,404,3,424,1,480,20,503z" fill="green" transform="translate(0,0)"/>
                                </a>
                                
                                <a @drop='onDrop($event, Activity.drag_items.find(e => e.name == "galaxy_arms"))' @dragover.prevent @dragenter.prevent>
                                    <path d="M581,172,539,98,507,68,458,44,387,22,299,28,229,59,141,124,94,198,76,238,66,317,81,376,89,385,101,408,110,417,126,426,133,437,164,445,168,466,180,495,202,527,236,559,294,603,315,607,395,585,366,551,335,530,320,508,359,470,375,482,400,496,402,527,422,543,419,579,451,576,404,375,362,366,346,364,334,357,344,323,373,289,405,226,441,238,468,244,470,254,457,274,432,311,419,330,410,345,403,366,379,390,395,435,412,481,450,571,530,561,538,599,541,612,648,574,705,538,740,474,759,364,756,342,661,250,648,220,635,216,611,227,598,225,596,233z" fill="green" transform="translate(0,0)"/>
                                </a>
                            </svg>
                        </template>
                    </transition>
                </v-col>
                <v-col cols="12" sm="2" style="padding-top: 45px;" :class="narrationClass">
                    <template>
                        <v-row v-for="(item, iterator) in Activity.drag_items" :key="item.class" :class="[item.class, item.status]" class="drag-item">
                            <v-img 
                                draggable
                                @dragstart='startDrag($event, item, iterator)'
                                :src="require(`@/assets/img/activities/activity_2/${item.item_img}`)"
                                contain
                                :class="item.status"
                            ></v-img>
                        </v-row>
                        <v-icon class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
                            west
                        </v-icon>
                        <transition name="fade-transition">
                            <template v-if="AstroIntroWatched">
                                <CountUpTimer ref="assessmentTimeUpTimer" :coins="assessmentCoins" class="timer"></CountUpTimer>
                            </template>
                        </transition>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-container>    
</template>
<script>
import CountUpTimer from '../components/CountUpTimer';
import mergeImages from 'merge-images';
import galaxy_placeholder from '@/assets/img/activities/activity_2/galaxy-placeholder.png'
export default {
    name: 'Activity2JoinMilkyway',
    components: {
        CountUpTimer
    },
    data(){
        return{
            milkyway: 'milky-way-attached.png',
            Activity: {
                id: '013',
                name: 'Put Together the Milkyway',
                drag_items: [
                    {name: 'galaxy_center', class:"galaxy-center", item_img: 'milkyway-center-icon.png',main_img: 'milkyway-center-main-img.png', status: 'active', narration: 'The center of the Milky Way is a supermassive blackhole', attempt_status:''},
                    {name: 'galaxy_arms', class:"galaxy_arms", item_img: 'milkyway-swirl-icon.png', main_img: 'milkyway-swirl-main-img.png', status: 'disabled', narration: 'These spiral arms are pinwheels within the galaxy that are made up of stars, planets, asteroids and hydrogen clouds that reach out from the center of the galaxy to its edges.', attempt_status:''},
                    {name: 'galaxy_nebula', class:"galaxy-nebula", item_img: 'nebula-icon.png', main_img: 'nebula-main-img.png', status: 'disabled', narration: 'Nebulae are necessary to create new stars. Nebulae are made up of gas and dust.', attempt_status:''},
                    {name: 'galaxy_star', class:"galaxy-star", item_img: 'stars-icon.png', main_img: 'star-main-img.png', status: 'disabled', narration: 'Stars are born when large gas clouds collapse under gravity.', attempt_status:''},
                    {name: 'galaxy_solar_system', class:"galaxy-solar-system", item_img: 'solar-system-icon.png', main_img: 'solar-system-main-img.png', status: 'disabled', narration: 'Our solar system is made up of a group of planets revolving around our Sun. They are held in place by gravity.', attempt_status:''},
                ],
                background: 'purple-galaxy-background.png'
            },
            currentSecondaryImg: 'galaxy-placeholder.png',
            AstroIntroWatched: false,
            drAstroNarration: '',
            showNarration: '',
            showDrAstro: '',
            wrongCount: 0,
            narrationText: 'Our galaxy is broken, can you help me put it back together? Remember kids use your cursor to drag and drop the icon in the correct order.',
            narrationLetterIndex: '',
            narrationSpeed: 80,
            galaxyMainImg: galaxy_placeholder,
            currentMergedImages: [galaxy_placeholder],
            iterator: 1,
            showCorrectAnimation: false,
            isDragging: false,
            svgHeight: 765,
            svgWidth: 640,
            galaxyMaxWidth: 765,
            galaxyMaxHeight: 640,
            increment: 0,
            galaxyPartsNarrationPlaying: false,
            narrationClass: 'astro-narration-playing',
        }
    },
    methods:{
        // handleDrop(word, data, event){
        //     console.log(word,data, event);
        // },
        svgDimensionOnScreenResize(){
            if(document.getElementById('galaxy-main-img')){
                this.svgWidth = document.getElementById('galaxy-main-img').clientWidth;
                this.svgHeight = document.getElementById('galaxy-main-img').clientHeight;
                setTimeout(() => {
                    this.galaxyMaxWidth = document.getElementById('milkway-svg-wrapper').clientWidth;
                    this.galaxyMaxHeight = document.getElementById('milkway-svg-wrapper').clientHeight;
                }, 100);
            }
        },
        /* touchMoved(){
            console.log('Moved');
        },
        touchTap(evt, item, index){
            console.log(evt);
            console.log(item);
            console.log(index);
        },
        touchLongtap(){
            console.log('touchLongtap');
        },
        touchMoving(){
            console.log('touchMoving');
        }, */
        startDrag(evt, item, index){
            evt.dataTransfer.dropEffect = 'move';
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('item', JSON.stringify(item));
            evt.dataTransfer.setData('index', index);
        },
        onDrop(evt, galaxy_location) {
            var _this = this, totalActivityTime;
            const item = JSON.parse(evt.dataTransfer.getData('item'));
            const index = evt.dataTransfer.getData('index');
            if(item.name == galaxy_location.name){
                item.status = 'disabled'
                _this.Activity.drag_items[index].status = 'disabled';
                if(item.attempt_status == ''){
                    _this.Activity.drag_items[index].attempt_status = 'correct';
                    this.assessmentCoins++;
                }
                _this.iterator++;
                totalActivityTime = this.$refs.assessmentTimeUpTimer.minutes + ":" + this.$refs.assessmentTimeUpTimer.seconds;
                //this.currentSecondaryImg = item.main_img;
                this.currentMergedImages.push(require(`@/assets/img/activities/activity_2/${item.main_img}`))
                mergeImages(_this.currentMergedImages)
                    .then(function(b64){
                        _this.galaxyMainImg = b64;
                        _this.showCorrectAnimation = true;
                        if(_this.$store.state.mute == false){
                            _this.play();
                        }
                        setTimeout(()=>{
                            _this.showCorrectAnimation = false;
                            if(item.narration){
                                setTimeout(()=>{
                                    _this.galaxyPartsNarrationPlaying = true;
                                    _this.narrationClass = 'astro-narration-playing';
                                    setTimeout(() => {
                                        _this.showDrAstro = true;
                                        setTimeout(() => {
                                            _this.showNarration = true;
                                            if(_this.Activity.drag_items[parseInt(index)+parseInt(1)]){
                                                _this.Activity.drag_items[parseInt(index)+parseInt(1)].status = 'active';
                                            }
                                            _this.wrongCount = 0;
                                            _this.galaxyPartsNarration(item.narration)
                                        }, 1000);
                                    }, 1000);
                                },500);
                            }
                            else{
                                if(_this.Activity.drag_items[parseInt(index)+parseInt(1)]){
                                    _this.Activity.drag_items[parseInt(index)+parseInt(1)].status = 'active';
                                }
                                _this.wrongCount = 0;
                                if(_this.iterator > _this.Activity.drag_items.length){  
                                    setTimeout(() => {
                                            _this.$store.state.activityTime = totalActivityTime;
                                            console.log('1')
                                            _this.$emit('completed');
                                            _this.$router.push({name: 'ActivityScoreboard', params: {activityID: this.$route.params.id, lessonID: this.$route.params.lessonID}})
                                    }, 3000);
                                }
                            }
                        }, 1000); 
                    });
            }
            else{
                if(item.attempt_status == ''){
                    _this.Activity.drag_items[index].attempt_status = 'wrong';
                }
                this.wrongCount++;
                if(_this.$store.state.mute == false){
                    _this.playWrong();
                }
                if(this.wrongCount >= 2){
                    item.status = 'disabled'
                    _this.Activity.drag_items[index].status = 'disabled';
                    _this.iterator++;
                    totalActivityTime = this.$refs.assessmentTimeUpTimer.minutes + ":" + this.$refs.assessmentTimeUpTimer.seconds;
                    this.currentMergedImages.push(require(`@/assets/img/activities/activity_2/${item.main_img}`))
                    setTimeout(()=>{
                        if(_this.$store.state.mute == false){
                            _this.playSecondWrong();
                        }
                        setTimeout(()=>{ mergeImages(_this.currentMergedImages)
                        .then(function(b64){
                            _this.galaxyMainImg = b64
                            if(item.narration){
                                setTimeout(()=>{
                                    _this.galaxyPartsNarrationPlaying = true;
                                    _this.narrationClass = 'astro-narration-playing';
                                    setTimeout(() => {
                                        _this.showDrAstro = true;
                                        setTimeout(() => {
                                            _this.showNarration = true;
                                            if(_this.Activity.drag_items[parseInt(index)+parseInt(1)]){
                                                _this.Activity.drag_items[parseInt(index)+parseInt(1)].status = 'active';
                                            }
                                            _this.wrongCount = 0;
                                            _this.galaxyPartsNarration(item.narration)
                                        }, 1000);
                                    }, 1000);
                                },1500);
                            }
                            else{
                                if(_this.Activity.drag_items[parseInt(index)+parseInt(1)]){
                                    _this.Activity.drag_items[parseInt(index)+parseInt(1)].status = 'active';
                                }
                                _this.wrongCount = 0;
                                if(_this.iterator > _this.Activity.drag_items.length){  
                                    setTimeout(() => {   
                                        _this.$store.state.activityTime = totalActivityTime;
                                        console.log('2')
                                        _this.$emit('completed');
                                        _this.$router.push({name: 'ActivityScoreboard', params: {activityID: this.$route.params.id, lessonID: this.$route.params.lessonID}})
                                    }, 3000);
                                }
                            }
                        });},1500);
                    }, 1500);
                }
            }
        },
        galaxyPartsNarration(narrationText){
            var _this = this, totalActivityTime;
            setTimeout(()=>{
                if (_this.narrationLetterIndex < narrationText.length) {
                    _this.drAstroNarration += narrationText.charAt(_this.narrationLetterIndex);
                    _this.narrationLetterIndex++;
                    setTimeout(_this.galaxyPartsNarration(narrationText), _this.narrationSpeed);
                }
                else{
                    totalActivityTime = this.$refs.assessmentTimeUpTimer.minutes + ":" + this.$refs.assessmentTimeUpTimer.seconds;
                    setTimeout(()=>{
                        _this.showNarration = false;
                        setTimeout(() => {
                            _this.showDrAstro = false;
                            setTimeout(() => {
                                _this.narrationClass = '';
                                _this.galaxyPartsNarrationPlaying = false;
                                setTimeout(() => {
                                    _this.narrationLetterIndex = 0;
                                    _this.drAstroNarration = '';
                                    if(_this.iterator > _this.Activity.drag_items.length){  
                                        setTimeout(() => { 
                                            _this.$store.state.activityTime = totalActivityTime;
                                            console.log('3')
                                            _this.$emit('completed');
                                            _this.$router.push({name: 'ActivityScoreboard', params: {activityID: this.$route.params.id, lessonID: this.$route.params.lessonID}});
                                        }, 3000);
                                    }
                                }, 100);
                            }, 1000);
                        }, 1000);
                    },2000);
                }
            },_this.narrationSpeed);
        },
        TyperwritingNarrationAnimation(){
            var _this = this;
            setTimeout(()=>{
                if (this.narrationLetterIndex < this.narrationText.length) {
                    this.drAstroNarration += this.narrationText.charAt(this.narrationLetterIndex);
                    this.narrationLetterIndex++;
                    setTimeout(_this.TyperwritingNarrationAnimation(), _this.narrationSpeed);
                }
                else{
                    setTimeout(()=>{
                        _this.showNarration = false;
                        setTimeout(() => {
                            _this.showDrAstro = false;
                            setTimeout(() => {
                                _this.AstroIntroWatched = true;
                                _this.narrationClass = '';
                                setTimeout(() => {
                                    _this.narrationLetterIndex = 0;
                                    _this.drAstroNarration = ''
                                    _this.svgDimensionOnScreenResize();
                                }, 100);
                            }, 1000);
                        }, 1000);
                    },2000);
                }
            },_this.narrationSpeed);
        },
        play() {
            var audio = new Audio(require(`@/assets/audio/dingsound.mp3`));
            audio.play()
            .then(() => {
                console.log("Audio is playing");
            })
            .catch(error => {
                console.log("error:" +error);
            });
        },
        playWrong(){
            var audio = new Audio(require(`@/assets/audio/buzzsound.mp3`));
            audio.play()
            .then(() => {
                console.log("Audio is playing");
            })
            .catch(error => {
                console.log("error:" +error);
            });
        },
        playSecondWrong(){
            var audio = new Audio(require(`@/assets/audio/chin-sound.mp3`));
            audio.play()
            .then(() => {
                console.log("Audio is playing");
            })
            .catch(error => {
                console.log("error:" +error);
            });
        }
    },
    computed:{
        assessmentCoins:{
            get(){
                return this.$store.state.assessmentCoins;
            },
            set(value){
                this.$store.state.assessmentCoins =value;
            }
        }
    },
    mounted(){
        this.$store.state.assessmentCoins = 0;
        this.$store.state.activityTime = "00:00";
        var _this = this
        setTimeout(()=>{
            _this.showDrAstro = true;
            setTimeout(() => {
                _this.showNarration = true;
                setTimeout(() => {
                    _this.TyperwritingNarrationAnimation();
                }, 1000);
            }, 1000);
        },2000);
        this.$nextTick(() => {
            window.addEventListener("resize", this.svgDimensionOnScreenResize);
        });
    },
    created() {
        
    },
    destroyed() {
        window.removeEventListener("resize", this.svgDimensionOnScreenResize);
    },
    
}
</script>
<style scoped>
.v-image{
    transition: all 0.3s;
}
.second-background image{
    max-height: calc(100vh - 100px);
    position: relative;
}
.drag-item{
    max-width: 100px;
    max-height: 100px;
    cursor: grab;
}

.drag-item.active{
    cursor: grab;
}
.drag-item.disabled{
    cursor: not-allowed;
}
.drag-item.active:active{
    cursor: grabbing;
}
.drag-item .v-image.active{
    animation: glow 2s infinite ease-in-out;
}
@keyframes glow {
  0%, 100% {
    filter: grayscale(0);
    transform: scale(1.2);
  }
  50% {
    filter: grayscale(1);
    transform: scale(1);
  }
}
.astro-narration-playing .drag-item .v-image.active{
    animation: none;
}
.drag-item .v-image.disabled{
    pointer-events: none;
    opacity: 0.5;
}
.dr-astro-narration{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 990;
}
.dr-astro{
    height: 400px;
    width: 350px;
    position: fixed;
    left: 30px;
    bottom: -25px;
    max-width: 275px;
    max-height: 275px;
}
.dr-astro .v-image{
    position: initial;
}
#dr-astro-narration{
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    font-family: 'Lomo', sans-serif;
    left: 50%;
    width: calc(60vw);
    max-height: calc(90vh);
    overflow-y: auto;
    /* padding: 30px 15px; */
    padding: 2.1em;
    border-radius: 50px;
    text-align: left;
}
.slide-fade-enter-active {
    transition: all 1s ease-in-out;
}
.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(280px);
    opacity: 0;
}
.back-btn, .back-icon{
    position: fixed;
    left: 15px;
    bottom: 30px;
    color: #fff;
    font-size: 46px;
    transition: all 0.3s;
    cursor: pointer;
    -webkit-text-stroke: 1px black;
}
.back-btn:hover, .back-icon:hover{
    text-shadow: 2px 2px 2px rgba(255,255,255,0.5);
    color: #6FC41E;
}
.correct-animation{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    
}
.correct-animation p{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 6rem;
    color: #ffffff;
    -webkit-text-stroke: 3px black;
    font-family: 'Lomo', sans-serif;
}
.remaining-attempts{
    color: #23C033;
    padding: 1em;
    position: fixed;
    top: 0;
    left: 0;
}
@media screen and (min-width: 961px) {
    #galaxy-secondary-img, #galaxy-main-img{
        position: fixed;
        max-height: calc(100vh - 100px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media screen and (max-width: 960px){
    #galaxy-secondary-img, #galaxy-main-img{
        position: relative;
        max-width: 100% !important;
        max-height: 100% !important
    }
    #milkway-svg-wrapper, .col-12{
        display: block;
        align-self: center;
    }
}
</style>