<template>
    <v-container fluid  v-bind:style="{ 'background-image': 'url(' + bgImg + ')' }" class="bg-container" :class="[showNarration ? 'bg-shadow' : '']" >
        <v-row class="header" v-if="activityInProgress && !showNarration" >
            <h3 class="remaining-attempts">Remaining Attempts: {{2 - this.wrongCount}}</h3>
            <CountUpTimer ref="assessmentTimeUpTimer" :coins="this.$store.state.assessmentCoins" class="timer"></CountUpTimer>
        </v-row>
        <br/>
        <!-- <v-row class="remaining-attempts" v-if="activityInProgress && !showNarration">
            <h3>Remaining Attempts:  {{3 - this.wrongCount}}</h3>
        </v-row> -->
        <v-icon v-if="activityInProgress && !showNarration" class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
            west
        </v-icon>
        <v-container v-if="activityInProgress && !showNarration" id="assessmentContainer" >
            <template 
                v-for= "(asset, index) in assets"
            >
                <div v-bind:key="index" v-if="index==currentIndex">
                    <!-- Question -->
                    <v-row class="mt-5"  >
                        <v-col cols="12" sm="1"></v-col>
                        <v-col cols="12" sm="10" align="left">
                            <h3 class="question-container">{{asset.question}}</h3>
                        </v-col>
                        <v-col cols="12" sm="1"></v-col>
                    </v-row> 
                    <!-- Options -->
                    <template 
                        v-for= "(option, i) in optionsRandomIndex"
                    >
                        <div :key="i">
                            <v-row class="mt-5 options-container">
                                <v-col cols="12" sm="1"></v-col>
                                <v-col cols="12" sm="10" align="left" >
                                    <span class="options">
                                        <span class="fake-list" v-if="i==0">A. </span>
                                        <span class="fake-list" v-else-if="i==1">B. </span>
                                        <span class="fake-list" v-else-if="i==2">C. </span>
                                        <span v-if="assets[option].answer==asset.answer" ref="correctOption" v-on:click="checkAns(i, true)" :class="'option-'+i" >{{assets[option].answer}}</span>
                                        <span v-else v-on:click="checkAns(i, false)" :class="'option-'+i" >{{assets[option].answer}}</span>
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="1"></v-col>
                            </v-row> 
                        </div>
                    </template> 
                </div>
            </template>
        </v-container>
        <transition name="scale-transition">
            <div class="correct-animation" v-if="showCorrectAnimation">
                <p>Correct!</p>
            </div>
        </transition>
        <div class="footer-container" v-if=showNarration>
            <v-row class="footer text-center" align="end" justify="center" >
                <v-col cols="12" sm="3" md="3">
                    <v-img :src="drAstro" eager contain max-height="200" max-width="200" width="200" v-if="showDrAstro" class="astro-img"></v-img>
                </v-col>
                <v-col cols="12" sm="8" md="7"  >
                    <div class="instruction-wrapper" > 
                        <p id="drAstroNarrationBox">{{drAstroNarration}}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="1" md="2"></v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import CountUpTimer from '../components/CountUpTimer';
import background from '@/assets/img/purple-galaxy-background.png';
import drAstro from '@/assets/img/activities/activity_3/dr-astro.png';

export default {
    name: 'Activity3Assessment',
    components: {
        CountUpTimer
    },
    data() {
        return {
            Activity: {
                id: '014',
                name: 'Assessment: Star Bubbles',
            },
            assessmentCoins: 0,
            wrongCount: 0,
            totalActivityTime: "",
            bgImg: background,
            drAstro: drAstro,
            showDrAstro: true,
            activityInProgress: false,
            currentIndex: 0,
            optionsRandomIndex: [],
            drAstroNarration: "",
            narrationText: "In our investigation today you have made many observations. Everything in space is related in some way to everything else. Everything is connected. The Universe is a system of connections. Using the observations you made throughout this lesson. Please describe these relationships that explain the organization of the Universe by reading the question and selecting the right answer to the corresponding question.",
            narrationSpeed: 70,
            narrationLetterIndex: 0,
            showNarration: true,//@M -
            selectCounter: 0,
            showCorrectAnimation: false,
            assets: [
                {question: "How are dust and gas related to a nebula?", answer: "Nebula are composed of dust and gas.", status: "false"},
                {question: "How is a nebula related to a star?", answer: "Stars are born in the center of a collapsed nebula.", status: "false"},
                {question: "How are stars related to a galaxy?", answer: "Galaxies are made up of stars.", status: "false"},
                {question: "How is a galaxy related to a solar system?", answer: "A galaxy is a system of stars and solar systems.", status: "false"},
                // {question: "How is the sun related to a solar system?", answer: "A sun is at the center of a solar system.", status: "false"},
                // {question: "How are planets related to the sun?", answer: "Planets are held in place by gravity from the planets and the Sun working together.", status: "false"},
                {question: "What force holds all of the components of a solar system in their place?", answer: "Gravity is the force that holds all of the components of the solar system in place.", status: "false"},
                {question: "What do we call the path that a planet travels on?", answer: "Planets travel in paths called orbits.", status: "false"},
                // {question: "What does this have to do with us?", answer: "The distance Earth is from the Sun and the path that Earth travels around the Sun contribute to conditions that provide us with the ability to support life on our planet, Earth; for example our climate and seasons.", status: "false"},
                // {question: "Where are we?", answer: "We are in our solar system - located on an arm of our galaxy.", status: "false"},
                {question: "Where is our solar system located?", answer: "It is located on an arm of our galaxy.", status: "false"},
                {question: "What is the name of the galaxy we live in?", answer: "We live in the Milky Way Galaxy.", status: "false"},
            ],
            currentBackground: 'purple-galaxy-background.png',  
        }
    },
    created(){
        this.getUniqueNumber(this.currentIndex);
        this.typerWritingNarration();
        this.activityInProgress = true;
        this.$store.state.assessmentCoins = 0;
        this.$store.state.activityTime = 0;

    },
    watch:{
        currentIndex(){
            this.getUniqueNumber(this.currentIndex);
            this.selectCounter = 0;
            if(this.currentIndex == this.assets.length){ 
                this.activityInProgress = false;    
                this.totalActivityTime = this.$refs.assessmentTimeUpTimer.minutes + ":" + this.$refs.assessmentTimeUpTimer.seconds;    
                this.$store.state.activityTime = this.totalActivityTime;
                this.$emit('completed');
                this.$router.push({name: 'ActivityScoreboard', params: {activityID: this.$route.params.id, lessonID: this.$route.params.lessonID}});        
            }
        }
    },
    methods:{
        // this method generates 2 random number between 0 and length of assets array
        getUniqueNumber(cIndex){
            this.optionsRandomIndex = []
            while(this.optionsRandomIndex.length < 2){
                var randomIndex = Math.floor(Math.random() * ((this.assets.length-1)-0+1)) + 0;
                if(cIndex!=randomIndex){
                   if((this.optionsRandomIndex.indexOf(randomIndex) === -1)){
                        this.optionsRandomIndex.push(randomIndex);
                    } 
                }
            }
            this.optionsRandomIndex.push(cIndex);
            for (let i = this.optionsRandomIndex.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [this.optionsRandomIndex[i], this.optionsRandomIndex[j]] = [this.optionsRandomIndex[j], this.optionsRandomIndex[i]];
            }
        },
        checkAns(i, result){
            var optionsElem = document.getElementsByClassName('options');
            for (var loopCounter = 0; loopCounter < optionsElem.length; loopCounter++ ) {
                optionsElem[loopCounter].style.pointerEvents = "none";
                optionsElem[loopCounter].style.background = "transparent";
            }
            if(result){
                this.showCorrectAnimation = true;
                this.play();
                setTimeout(()=>{
                    this.showCorrectAnimation = false;
                }, 1000); 
                if(this.selectCounter == 0){
                    this.assessmentCoins++;
                    this.$store.state.assessmentCoins = this.assessmentCoins;
                }
                document.getElementsByClassName('option-'+i).forEach(element => {
                    element.parentElement.style.color = "white";
                    element.parentElement.classList.add("green");
                });
                setTimeout(() => {
                    this.wrongCount = 0;
                    this.currentIndex++;
                    optionsElem = document.getElementsByClassName('options');
                    for (loopCounter = 0; loopCounter < optionsElem.length; loopCounter++ ) {
                        optionsElem[loopCounter].style.pointerEvents = "auto";
                    }
                }, 2000);
            }else{
                this.wrongCount++;  
                this.selectCounter = 1;
                var finalTime = 2000;
                document.getElementsByClassName('option-'+i).forEach(element => {
                    element.parentElement.classList.add("red");
                    element.parentElement.style.color = "white";
                    finalTime = 2000;
                    if(this.wrongCount >= 2){
                        finalTime = 4000;
                        this.$refs['correctOption'][0].classList.add("green");
                        this.$refs['correctOption'][0].style.color = "white";
                    }
                });
                setTimeout(() => {
                    // this.wrongCount++;  
                    document.getElementsByClassName('option-'+i).forEach(element => {
                        element.parentElement.classList.remove("red");
                        element.parentElement.style.color = "";
                        optionsElem = document.getElementsByClassName('options');
                        for (loopCounter = 0; loopCounter < optionsElem.length; loopCounter++ ) {
                            optionsElem[loopCounter].style.pointerEvents = "auto";
                        }
                        if(this.wrongCount >= 2){
                            this.wrongCount = 0;
                            this.currentIndex++;
                            this.assets[i].status = false;
                        }
                    });
                }, finalTime);  
            }  
        },
        typerWritingNarration(){
            var _this = this;
            setTimeout(()=>{
                if (this.showNarration && this.narrationLetterIndex < this.narrationText.length) {
                    if (parseInt(this.narrationLetterIndex) != parseInt(188)) {
                        var charToAppend = this.narrationText.charAt(this.narrationLetterIndex);
                        if(charToAppend === '.'){
                            charToAppend = "•"
                        }
                        this.drAstroNarration += charToAppend;
                        // document.getElementById("drAstroNarration").innerHTML += charToAppend;
                        this.narrationLetterIndex++;
                        setTimeout(_this.typerWritingNarration(), _this.narrationSpeed);
                    }else{
                        setTimeout(()=>{
                            // document.getElementById("drAstroNarration").innerHTML = "";    
                            this.drAstroNarration = "";
                            this.narrationLetterIndex++;
                            setTimeout(_this.typerWritingNarration(), _this.narrationSpeed);
                        },4000);
                    }
                }
                else{
                    setTimeout(()=>{
                        _this.showNarration= false;
                        this.showDrAstro = false;
                        // document.getElementById('assessmentContainer').classList.remove("bg-shadow");
                    },4000);
                }
            },_this.narrationSpeed);
        },
        play() {
            var audio = new Audio(require(`@/assets/audio/dingsound.mp3`));
            audio.play()
            .then(() => {
                console.log("Audio is playing");
            })
            .catch(error => {
                console.log("error in playing audio: " +error);
            });
        }
    }
}
</script>

<style scoped>

    .bg-container{
        height: 100%;
        font-family: 'Lomo', sans-serif;
    }
    
    .bg-shadow{
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7);
    }

    .question-container{
        color: #E2D14B;  
        letter-spacing: 2px;
        text-overflow: scroll;
        margin-left: -2.1em; 
        padding-top: 0;
    }

    .options-container{
        color: white;
        letter-spacing: 2px;
        text-overflow: scroll;
    }

    .options-container span{
        font-size: 18px;
        padding: 5px;
    }

    .question-container, .options-container span:hover{
        cursor :pointer;
    }
    
    .options-container .options:hover{
        color: #23C033;
    }

    .options{
        padding: 0!important;
    }

    .options span{
        padding: 0!important;
    }

    #assessmentContainer{
        background-color: rgba(218, 223, 225, .2);
        /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7); */
        padding-bottom: 3rem;
    }

    .instruction-wrapper{
        background: #FFFFFF;
        color: #000000;
        text-align: left !important;
        min-width: 67vw;     
        overflow-y: auto;
        border-radius: 50px;
        position: fixed;
        padding: 2em;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .instruction-wrapper p{
        letter-spacing: 2px;
        word-spacing: 5px;
        margin: 0;
        line-height: 1.7;
    }

    .header{
        /* float: right; */
        margin-top: 10px;
        margin-right: 10px;
    }

    .remaining-attempts{
        color: #23C033;
        padding: 1em;
    }

    .back-btn, .back-icon{
        position: fixed;
        left: 15px;
        bottom: 30px;
        color: #fff;
        font-size: 46px;
        transition: all 0.3s;
        cursor: pointer;
        -webkit-text-stroke: 1px black;
    }

    .back-btn:hover, .back-icon:hover{
        text-shadow: 2px 2px 2px rgba(255,255,255,0.5);
        color: #6FC41E;
    }

    .timer{
        background: transparent;
        margin-left: auto;
    }

    .assessmentCoins{
        color: #FFFFFF;
        background: #000000;
    }

    .footer-container{
        width: 100%;
        height: 100vh;
        background: transparent;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
    }

    .footer{
        position: absolute;
        bottom: 1em;
    }
    
    .fake-list{
        margin-left: -2.1em; 
        text-indent: 2em    
    }
    .correct-animation{
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        
    }
    .correct-animation p{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 6rem;
        color: #ffffff;
        -webkit-text-stroke: 3px black;
        font-family: 'Lomo', sans-serif;
    }
</style>