<template>
    <v-container fluid fill-height v-bind:style="{'background-image' : 'url(' + require(`@/assets/img/` + currentBackground) + ')', 'background-size': 'cover'}">
        <v-container class="dropabble-boxes-wrapper">
            <v-row>
                <v-col cols="12" class="col-4-3">
                    col 1
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 2
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 3
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 4
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 5
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 6
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 7
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 8
                </v-col>
                <v-col cols="12" class="col-4-3">
                    col 9
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
export default {
    name: 'solarSystemActivity5',
    data(){
        return{
            currentBackground: 'purple-galaxy-background.png'
        }
    },
    mounted(){

    },
    methods:{

    },
    computed:{

    }
}
</script>
<style scoped>
.col-4-3{
    height: 150px;
    position: relative;
    border-top: 3px #fff solid;
    -o-border-image: linear-gradient(270deg,#fff 33%, transparent 0,transparent 67%,#fff 0) 1;
    border-image: linear-gradient(270deg,#fff 33%,transparent 0,transparent 67%,#fff 0) 1;
    border-left: 1.5px #fff solid;
    border-right: 1.5px #fff solid;
    border-bottom: 3px #fff solid;
    color: #fff;
}
.col-4-3:before{
    position:absolute; 
    content:' ';
    width:100%;
    height: 100%;
    border-color:#fff;
    border-style:solid;
    top:3px;
    left:0;
    border-width: 0px 0 3px 0px
}
.col-4-3:first-child{
    border-left: 3px #fff solid;
}
.col-4-3:last-child{
    border-right: 3px #fff solid;
}
.dropabble-boxes-wrapper{
    align-self: flex-end;
    margin-bottom: 30px;
}

@media screen and (min-width: 768px){
   .col-4-3{
        flex: 0 0 11.111111%;
        max-width: 11.111111%;
    } 
}
</style>