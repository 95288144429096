<!--
    this components accepts a props named "coins" and it renders that coins (if props coins not passed, then it will not show coin icon and coins)
    It keep track of time in variables 'minutes' and 'seconds'
    It also renders a Setting icon to go to Settings page
-->
<template>
    <v-row class="coinAndTimer" no-gutters>
        <!-- Display Coin -->
        <v-col cols="12" sm="4" class="coin text-center" align-self="center"> 
            <v-row no-gutters v-if="coins!=null">
                <v-col cols="12" sm="6">
                    <v-img src="@/assets/img/activities/activity_3/coin-box.png" eager contain></v-img>
                </v-col>
                <v-col cols="12" sm="6" align-self="center">
                    <span>{{ coins }}</span>
                </v-col>
            </v-row>
        </v-col>
        <!-- Display Timer -->
        <v-col cols="12" sm="5" class="timer-clock text-center" align-self="center">
            <span>{{minutes}} <b class="timeSeparator">:</b> {{ seconds }}</span>
        </v-col>
        <!-- Display Settings Icon -->
        <v-col cols="12" sm="3" align-self="center" class="text-center">
            <!-- <img @click="$router.push({path: '/settings', name: 'Settings'})" style="vertical-align:middle" width="75" src="@/assets/img/settings-gear.svg"> -->
            <v-icon large class="setting-icon" v-on:click="$router.push({path: '/settings', name: 'Settings'})">
                settings
            </v-icon>
        </v-col>
    </v-row>
</template>

<script>

    export default {
        name: 'CountUpTimer',
        props: ["coins"],
        data() {
            return {
                timerEnabled: true,
                timerCount: 0,
                seconds: 0,
                minutes: 0
            }
        },

        watch: {
            timerCount: {
                handler(value) {
                    if (value >= 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount++;
                            this.seconds = this.setDigitsLength(this.timerCount % 60);
                            this.minutes = this.setDigitsLength(parseInt(this.timerCount / 60));
                        }, 1000);
                    }
                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
        },
        computed: {
            getRandomCoin(){
                return Math.floor(Math.random() * (20-1+1)) + 1;
            }
        },
        methods: {

            play() {
                this.timerEnabled = true;
            },

            pause() {
                this.timerEnabled = false;
            },

            setDigitsLength(val) {
                var valString = val + "";
                if (valString.length < 2) {
                    return "0" + valString;
                } else {
                    return valString;
                }
            },
        }
    }

</script>

<style scoped>

    .coinAndTimer{
        font-family: 'Roboto', sans-serif;
        padding: 15px;
        background-color: rgba(0,0,0,0.5) !important;
        border-radius: 0 0 0 30px;
        min-width: 250px;
        max-width: 300px;
    }

    .timer{
        color:#23C033;
        font-size: 1.5rem;
        font-weight: bold;
    }

    .timeSeparator{
        font-size: 20px;
    }

    .coin, .coin-icon{
        color: #FFD700;
    }
    
    .setting-icon{
        color: #FFFFFF;
        -webkit-text-stroke: 3px black;
    }

    .setting-icon:hover{
        cursor: pointer;
        transition: none !important;
        color:#23C033;
    }

</style>