<template>
    <v-container fluid class="main-container">
        <v-container
            fluid
            v-bind:style="{ 'background-image': 'url(' + bgImg + ')' }"
            class="bg-container"
        >
            <span class="star" id="starAnimation" v-if="showStarAnimation"
                ><img src="@/assets/img/activities/activity_10/star.png"
            /></span>

            <v-row>
                <CountUpTimer
                    ref="assessmentTimeUpTimer"
                    class="timer"
                ></CountUpTimer>
            </v-row>
            <v-row>
                <v-col cols="12" sm="2">
                    <!--Back Button  -->
                    <v-icon
                        ref="backIcon"
                        class="back-icon"
                        @click="
                            $router.push({
                                name: 'Lessons',
                                params: {
                                    id: '6a6b5c5d9e9',
                                    name: 'Earth and Space',
                                    slug: 'earth-and-space',
                                },
                            })
                        "
                    >
                        west
                    </v-icon>
                    <!-- Two B Icon -->
                    <transition name="fade-transition">
                        <div class="two-b">
                            <transition name="slide-fade">
                                <v-img
                                    :src="twoBImg"
                                    width="250"
                                    height="300"
                                    v-if="showTwoB"
                                    eager
                                    contain
                                ></v-img>
                            </transition>
                        </div>
                    </transition>
                </v-col>
                <v-col cols="12" sm="8">
                    <!-- Template to show Dr astro narrations -->
                    <transition name="fade-transition">
                        <div
                            :class="[showNarration ? 'dr-astro-narration' : '']"
                        >
                            <transition name="fade-transition">
                                <div
                                    :class="[
                                        showDrAstroLeft
                                            ? 'dr-astro-left'
                                            : 'dr-astro',
                                    ]"
                                >
                                    <transition name="slide-fade">
                                        <v-img
                                            :src="drAstroImg"
                                            v-if="showDrAstro"
                                            eager
                                            contain
                                        ></v-img>
                                    </transition>

                                    <transition name="scale-transition">
                                        <div
                                            :id="[
                                                showDrAstroLeft
                                                    ? 'dr-astro-narration-left'
                                                    : 'dr-astro-narration',
                                            ]"
                                            v-if="showNarration"
                                        >
                                            <p id="dr-astro-narration-text-box">
                                                {{ drAstroNarrationText }}
                                            </p>
                                        </div>
                                    </transition>
                                </div>
                            </transition>
                        </div>
                    </transition>
                </v-col>
            </v-row>
            <v-row>
                <!-- Dr astro star video -->
                <transition name="fade-transition">
                    <div class="stars-video-wrapper" v-if="showStarVideo">
                        <video
                            muted
                            autoplay
                            id="starsVideo"
                            @ended="starsVideoEnded"
                            @loadeddata="checkLoading"
                        >
                            <source :src="videoURL()" type="video/mp4" />
                        </video>
                    </div>
                </transition>

                <!-- Loader -->
                <transition name="fade-transition">
                    <div
                        class="full-screen-loader"
                        v-if="videoLoading && showStarVideo"
                    >
                        <v-progress-circular
                            indeterminate
                            color="#000000"
                            class="progressLoader"
                        >
                        </v-progress-circular>
                    </div>
                </transition>

                <!-- Next button -->
                <v-row>
                    <transition name="fade-transition">
                        <v-btn
                            v-if="nextActivityBtn"
                            class="skip custom-font next-activity-btn"
                            @click="goToNextActivity"
                            >Next Activity</v-btn
                        >
                    </transition>
                </v-row>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import background from '@/assets/img/activities/activity_10/night-beach-stars.png';
import nightStarBg from '@/assets/img/activities/activity_10/night-stars.jpg';
import drAstroImg from '@/assets/img/activities/activity_1a/dr-astro.png';
import twoBImg from '@/assets/img/activities/activity_10/2b-Happy-talk.png';
import CountUpTimer from '../components/CountUpTimer';

export default {
    name: 'Activity10Exploring',
    components: {
        CountUpTimer,
    },
    data() {
        return {
            Activity: {
                id: '023',
                name: 'Exploring Distance And Apparent Brightness',
                Narration: [
                    {
                        id: 0,
                        text:
                            'Well, what do you think or conclude? Is there a pattern that has emerged? Why do some stars appear brighter than others?',
                    },
                    {
                        id: 1,
                        text:
                            'distance from Earth, brightness and luminosity, mass, chemical composition, age',
                    },
                    {
                        id: 2,
                        text:
                            'If you guessed that it is due to distance and apparent brightness or luminosity, then you are right!^Stars appear brighter as we get closer to them but their light dims as we get farther away.',
                    },
                    {
                        id: 3,
                        text:
                            'Did you know……. that if you took a star and moved it twice as far away, it would appear 1/4 as bright; if you moved it four times the distance, it would appear 1/16 as bright. Stars will appear brighter if they are closer to Earth.',
                    },
                ],
            },
            bgImg: background,
            nightStarBg: nightStarBg,
            drAstroImg: drAstroImg,
            twoBImg: twoBImg,
            showNightStar: false,
            showDrAstro: false,
            showDrAstroLeft: false,
            showTwoB: false,
            showNarration: false,
            drAstroNarrationText: '',
            animationSpeed: 80,
            narrationTimeout: null,
            isRequestBusy: false,
            narrationCurrentIndex: -1,
            showStarAnimation: false,
            showStarVideo: false,
            videoLoading: true,
            nextActivityBtn: false,
        };
    },
    created() {
        console.log('Created');
    },
    mounted() {
        this.$store.state.activityTime = '00:00';
        clearTimeout(this.narrationTimeout);
        this.showTwoB = true;
        // start narration
        setTimeout(() => {
            this.narrationCurrentIndex = 0;
        }, 1500);

        // document.addEventListener("visibilitychange", function() {
        //     if (document.hidden) {
        //         this.narrationSpeed = 1000;
        //         console.log("Hidden");
        //     }
        //     else {
        //         this.narrationSpeed = 80;
        //         console.log("SHOWN");
        //     }
        // });
    },
    destroyed() {
        clearTimeout(this.narrationTimeout);
    },
    watch: {
        narrationCurrentIndex() {
            if (
                !this.isRequestBusy &&
                this.narrationCurrentIndex < this.Activity.Narration.length
            ) {
                this.displayNarration(
                    this.Activity.Narration[this.narrationCurrentIndex].text
                );
            }
        },
    },
    methods: {
        /** method to show dr. astro narration */
        drAstroNarrationAnimation(
            astroNarrationLetterIndex,
            narrationText,
            narrationSpeed,
            renderCondition,
            appendTo
        ) {
            if (astroNarrationLetterIndex == 0) {
                this.drAstroNarrationText = '';
            }
            this.narrationTimeout = setTimeout(() => {
                if (
                    renderCondition &&
                    astroNarrationLetterIndex < narrationText.length
                ) {
                    var charToAppend = narrationText.charAt(
                        astroNarrationLetterIndex
                    );
                    if (charToAppend != '^') {
                        this.drAstroNarrationText += charToAppend;
                        astroNarrationLetterIndex++;
                        setTimeout(
                            this.drAstroNarrationAnimation(
                                astroNarrationLetterIndex,
                                narrationText,
                                narrationSpeed,
                                renderCondition,
                                appendTo
                            )
                        );
                    } else {
                        setTimeout(() => {
                            this.drAstroNarrationText = '';
                            this.showNarration = false;
                            setTimeout(() => {
                                this.showNarration = true;
                                astroNarrationLetterIndex++;
                                setTimeout(
                                    this.drAstroNarrationAnimation(
                                        astroNarrationLetterIndex,
                                        narrationText,
                                        narrationSpeed,
                                        renderCondition,
                                        appendTo
                                    )
                                );
                            }, 1500);
                        }, 2000);
                    }
                } else {
                    setTimeout(() => {
                        this.showNarration = false;
                        if (this.narrationCurrentIndex == 2) {
                            // this.showDrAstro = false;
                            this.showStarAnimation = false;
                            document.getElementById(
                                'starAnimation'
                            ).style.visibility = 'hidden';
                        }
                    }, 2000);
                }
            }, narrationSpeed);
        },

        displayNarration(narrationText) {
            var delay = 3500;

            if (narrationText.indexOf('^') > -1) {
                delay = delay + 3500;
                console.log('Updated delay.');
            }
            this.isRequestBusy = true;
            this.showDrAstro = true;
            console.log('Started!');
            setTimeout(() => {
                this.showNarration = true;
                this.drAstroNarrationAnimation(
                    0,
                    narrationText,
                    this.animationSpeed,
                    this.showNarration,
                    'dr-astro-narration-text-box'
                );
                setTimeout(() => {
                    console.log('Final Time Out');
                    this.isRequestBusy = false;
                    clearTimeout(this.narrationTimeout);
                    this.startNextNarration();
                }, narrationText.length * this.animationSpeed + delay);
            }, 1000);
        },

        startNextNarration() {
            if (
                this.narrationCurrentIndex <
                this.Activity.Narration.length - 1
            ) {
                var updatedIndex = this.narrationCurrentIndex + 1;
                if (updatedIndex == 2) {
                    this.bgImg = this.nightStarBg;
                    this.showTwoB = false;
                    this.showDrAstro = false;
                    this.showNarration = false;
                    setTimeout(() => {
                        // update 2b template here
                        this.showDrAstroLeft = true;
                        setTimeout(() => {
                            this.showStarAnimation = true;
                            this.narrationCurrentIndex++;
                        }, 1000);
                    }, 1000);
                } else {
                    this.narrationCurrentIndex++;
                }
                if (updatedIndex == 3 && this.showStarAnimation) {
                    document.getElementById('starAnimation').style.visibility =
                        'hidden';
                    this.showStarAnimation = false;
                }
            } else {
                this.narrationsEnded();
            }
        },

        narrationsEnded() {
            this.showStarVideo = true;
        },

        /** method to call when stars video ended */
        starsVideoEnded() {
            this.nextActivityBtn = true;
        },

        /** method to set video loading to false */
        checkLoading() {
            this.videoLoading = false;
        },
        /** method to set the url of the stars video */
        videoURL() {
            var timestamp = new Date().getTime();
            return (
                require(`@/assets/videos/activities/activity_10/astro-stars-narration.mp4`) +
                '?' +
                timestamp
            );
        },
        /** method to go to next activity */
        goToNextActivity() {
            this.$router
                .push({
                    name: 'Activity',
                    params: {
                        id: this.$store.state.nextActivity.id,
                        name: this.$store.state.nextActivity.name,
                    },
                })
                .catch((err) => {
                    console.log('Error: ' + err);
                });
        },
    },
};
</script>

<style scoped>
.main-container {
    background: linear-gradient(
        to bottom,
        rgb(4, 3, 21) 0%,
        rgb(13, 12, 54) 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgb(4, 3, 21) 0%,
        rgb(13, 12, 54) 100%
    );
    background: -o-linear-gradient(top, rgb(4, 3, 21) 0%, rgb(13, 12, 54) 100%);
    background: -ms-linear-gradient(
        top,
        rgb(4, 3, 21) 0%,
        rgb(13, 12, 54) 100%
    );
    background: -moz-linear-gradient(
        top,
        rgb(4, 3, 21) 0%,
        rgb(13, 12, 54) 100%
    );
    background: linear-gradient(
        to bottom,
        rgb(4, 3, 21) 0%,
        rgb(13, 12, 54) 100%
    );
    height: 100%;
    padding: 0;
}
.bg-container {
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
}
.dr-astro-narration {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 990;
}
.dr-astro {
    position: fixed;
    right: 30px;
    bottom: 0px;
    max-width: 250px;
    max-height: 300px;
}
.dr-astro-left {
    position: fixed;
    left: 30px;
    bottom: 0px;
    max-width: 300px;
    max-height: 400px;
}
.dr-astro .v-image {
    position: initial;
}

#dr-astro-narration {
    position: fixed;
    background-color: #fff;
    font-family: 'Lomo', sans-serif;
    top: 60%;
    transform: translate(-60%, -40%);
    background-color: #fff;
    font-family: 'Lomo', sans-serif;
    left: 60%;
    width: calc(45vw);
    max-height: calc(90vh);
    overflow-y: auto;
    padding: 2.1em;
    border-radius: 50px;
    text-align: left;
}

#dr-astro-narration-left {
    position: fixed;
    background-color: #fff;
    font-family: 'Lomo', sans-serif;
    bottom: 10%;
    left: 60%;
    transform: translate(-60%, -10%);
    background-color: #fff;
    font-family: 'Lomo', sans-serif;
    width: calc(45vw);
    max-height: calc(90vh);
    overflow-y: auto;
    padding: 2.1em;
    border-radius: 50px;
    text-align: left;
}

#dr-astro-narration p,
#dr-astro-narration-left p {
    margin: 0;
    line-height: 1.7;
}

.two-b {
    position: fixed;
    left: 30px;
    bottom: 0px;
    max-width: 250px;
    max-height: 300px;
}

.two-b .v-image {
    position: initial;
}
#starsVideo {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(95vw);
    max-height: calc(95vh);
    border: 5px solid rgba(255, 255, 255, 1);
    border-style: double;
    transition: all 0.3s;
}
.stars-video-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

.next-activity-btn {
    z-index: 1000!important;
}

.back-icon {
    position: fixed;
    left: 15px;
    top: 30px;
    color: #fff;
    font-size: 46px;
    transition: all 0.3s;
    cursor: pointer;
    -webkit-text-stroke: 1px black;
}
.back-icon:hover {
    text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
    color: #6fc41e;
}

.slide-fade-enter-active {
    transition: all 1s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(300px);
    opacity: 0;
}
.star {
    position: absolute;
    top: 25%;
    right: 25%;
    width: auto;
    height: auto;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.45);
    color: transparent;
    box-shadow: 0 0 45px 20px rgb(255 255 255 / 40%),
        0 0 60px 30px rgb(255 255 255 / 20%),
        0 0 65px 20px rgb(255 255 255 / 15%);
}

.star img {
    width: 4rem;
    height: 4rem;
    filter: invert(1) blur(1px);
    animation: starAnim 30s linear 1;
}

@keyframes starAnim {
    0% {
        opacity: 1;
        width: 4rem;
        height: 4rem;
    }
    50% {
        opacity: 0.5;
        width: 2rem;
        height: 2rem;
    }
    100% {
        box-shadow: none;
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* tablet */
@media screen and (max-width: 769px) {
    #dr-astro-narration-left {
        left: 70% !important;
    }
    #dr-astro-narration {
        left: 50% !important;
    }
}
/* laptop */
@media screen and (max-width: 1000px) and (min-width: 769px) {
    #dr-astro-narration-left {
        left: 60% !important;
    }
    #dr-astro-narration {
        left: 70% !important;
    }
}
@media screen and (min-width: 1001px) {
}

/* .star {
    height: 100px;
    width: 100px;
    border-radius: 5px;
    position: absolute;
}

.star:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "+"; /* /002B use the hex value here... */
/*font-size: 50px;
    color: #FFF;
    line-height: 100px;
    text-align: center;
    filter:drop-shadow(0 0 0.5rem #ffffff) blur(1px) brightness(100%);
    transform: scale(2.0) rotate(20deg);
    animation: star 2.5s ease-in infinite;
}

 @keyframes star {
    0% {
        opacity: 0;
        width: 0px;
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }

    10% {
        opacity: 0;
        width: 5px;
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }

    30%{
        opacity: 0.25;
        width: 10px;
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }

    50%{
        opacity: 0.5;
        width: 20px;
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }

    70%{
        width: 30px;
        opacity: 0.75;
        filter: drop-shadow(0 0 0.75rem #ffffff);
    }

    90% {
        opacity: 1;
        width: 40px;
        filter: drop-shadow(0 0 0.75rem #ffffff);
        /* /002B use the hex value here... */
/*}
} */
</style>
